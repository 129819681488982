import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import { url } from 'src/Helpers/utils/Constant';
import { ICompanyInfo, ImemberData } from 'src/Types/module/member/memberTypes';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import axios from 'axios';
import Toaster from 'src/Toaster/Toaster';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { useParams } from 'react-router';

const MemberInfoDetails = ({ memberInfo }: { memberInfo: ICompanyInfo }) => {
  const Toast = Toaster();
  const [memberData, setMemberData] = useState<ImemberData>({
    accountStatus: '',
    infoStatus: '',
    paymentStatus: '',
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [memberAllData, setMemberAllData] = useState<ICompanyInfo>(memberInfo);
  const { memberId } = useParams();

  ({ memberId });

  useEffect(() => {
    setMemberAllData(memberInfo);
    setMemberData({
      ...memberData,
      accountStatus: memberInfo?.user_member_account_status,
      infoStatus: memberInfo?.user_member_info_verified_status,
      paymentStatus: memberInfo?.user_member_payment_verified_status,
    });
  }, [memberInfo]);

  const {
    user_member_id,
    user_member_email,
    user_member_phone,
    user_member_representative_name,
    user_member_account_status,
    user_member_info_verified_status,
    user_member_payment_verified_status,
    user_member_membership_id,
    user_member_representative_full_address,
    user_member_representative_nid_no,
    user_member_representative_phone,
    user_member_representative_designation,
    user_member_representative_resident,
    user_member_representative_nid_front,
    user_member_last_subscribtion_payment_date,
    user_member_representative_nid_back,
    user_member_approved_date,
    zone_name,
    zone_id,
    user_member_active_status,
  } = memberAllData || {};

  const handleMemberUpdate = async () => {
    const body = {
      user_member_account_status: memberData.accountStatus,
      user_member_info_verified_status: memberData.infoStatus,
      user_member_payment_verified_status: memberData.paymentStatus,
    };

    body;

    try {
      const { data } = await axios.put(
        `${url}/api/atab/user-member/admin/update/member/${memberId}`,
        body
      );

      ({ data });

      if (data.success) {
        setMemberAllData({
          ...memberAllData,
          user_member_account_status: memberData.accountStatus,
          user_member_info_verified_status: memberData.infoStatus,
          user_member_payment_verified_status: memberData.paymentStatus,
        });

        Toast.fire({
          icon: 'success',
          title: 'Member succesfully updated',
        });
      }
    } catch (err) {
      err;
      Toast.fire({
        icon: 'error',
        title: `Update Failed ! \n ${err.response?.data?.message}`,
      });
    }
    setShowModal(false);
  };

  return (
    <Box className='bg-style yellowshade' mr={1}>
      <Box className='mt-2 company-info'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2>Member information</h2>
          <Button onClick={() => setShowModal(!showModal)} variant='text'>
            Edit
          </Button>
        </Box>
        <ul>
          <li>
            <span>Member ID</span>: <p>{user_member_id}</p>
          </li>
          <li>
            <span>Status</span>: <p>{user_member_active_status}</p>
          </li>
          <li>
            <span>Zone</span>: <p>{zone_name}</p>
          </li>
          <li>
            <span>Phone</span>: <p>{user_member_phone}</p>
          </li>
          <li>
            <span>Email</span>: <p>{user_member_email}</p>
          </li>
          <li>
            <span>Representative Name</span>:{' '}
            <p>{user_member_representative_name}</p>
          </li>
          <li>
            <span>Account Status</span>: <p>{user_member_account_status}</p>
          </li>
          <li>
            <span>Info Verified Status</span>:{' '}
            <p>{user_member_info_verified_status}</p>
          </li>
          <li>
            <span>Payment Verified Status</span>:{' '}
            <p>{user_member_payment_verified_status}</p>
          </li>
          <li>
            <span>Membership ID</span>: <p>{user_member_membership_id}</p>
          </li>
          <li>
            <span>Representative Full Address</span>:{' '}
            <p>{user_member_representative_full_address}</p>
          </li>
          <li>
            <span>Representative Nid No</span>:{' '}
            <p>{user_member_representative_nid_no}</p>
          </li>
          <li>
            <span>Representative Phone No</span>:{' '}
            <p>{user_member_representative_phone}</p>
          </li>
          <li>
            <span>Representative Designation</span>:{' '}
            <p>{user_member_representative_designation}</p>
          </li>
          <li>
            <span>Representative Resident</span>:{' '}
            <p>{user_member_representative_resident}</p>
          </li>
          <li>
            <span>Approved Date</span>: <p>{user_member_approved_date}</p>
          </li>
          <li>
            <span>Representative NID Front</span>:{' '}
            <a
              href={`${url}/api/download/file/company_files/${user_member_representative_nid_front}`}
            >
              {user_member_representative_nid_front}
            </a>
          </li>
          <li>
            <span>Representative NID Back</span>:{' '}
            <a
              href={`${url}/api/download/file/company_files/${user_member_representative_nid_back}`}
            >
              {user_member_representative_nid_back}
            </a>
          </li>
          <li>
            <span>Last subscription payment Date</span>:{' '}
            <p>{user_member_last_subscribtion_payment_date}</p>
          </li>
        </ul>
      </Box>

      <ModalsWrapper
        showModal={showModal}
        setShowModal={setShowModal}
        modalData={{ title: 'Member Info Edit' }}
      >
        <Box>
          <FormControl required margin='normal' sx={{ minWidth: '160px' }}>
            <InputLabel required id='demo-simple-select-label'>
              Status
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={memberData.accountStatus}
              label='Status'
              required
              onChange={(e: any) =>
                setMemberData({ ...memberData, accountStatus: e.target.value })
              }
            >
              <MenuItem value='pending'>Pending</MenuItem>
              <MenuItem value='active'>Valid</MenuItem>
              <MenuItem value='rejected'>Rejected</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            required
            margin='normal'
            sx={{ minWidth: '160px', marginX: 3 }}
          >
            <InputLabel required id='demo-simple-select-label'>
              Payment Status
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={memberData.paymentStatus}
              label='Payment Status'
              required
              onChange={(e: any) =>
                setMemberData({ ...memberData, paymentStatus: e.target.value })
              }
            >
              <MenuItem value='pending'>Pending</MenuItem>
              <MenuItem value='approved'>Approved</MenuItem>
              <MenuItem value='rejected'>Rejected</MenuItem>
            </Select>
          </FormControl>

          <FormControl required margin='normal' sx={{ minWidth: '160px' }}>
            <InputLabel required id='demo-simple-select-label'>
              Verified Status
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={memberData.infoStatus}
              label='Verified Status'
              required
              onChange={(e: any) =>
                setMemberData({ ...memberData, infoStatus: e.target.value })
              }
            >
              <MenuItem value='pending'>Pending</MenuItem>
              <MenuItem value='approved'>Approved</MenuItem>
              <MenuItem value='rejected'>Rejected</MenuItem>
            </Select>
          </FormControl>
          <Box mt={3} textAlign='center'>
            <Button onClick={() => handleMemberUpdate()} variant='contained'>
              Submit
            </Button>
          </Box>
        </Box>
      </ModalsWrapper>
    </Box>
  );
};

export default MemberInfoDetails;

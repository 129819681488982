import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  CardContent,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { LoadingButton } from '@mui/lab';

interface IProps {
  details: any;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const UpdateEventB2BStatus = ({ details, setReload }: IProps) => {
  const { user } = useAuthContext();
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('');
  const Toast = Toaster();

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const obj = {
        status: status,
      };

      setLoading(true);
      const { data } = await axios.put(
        `${url}/api/fair/event/update/b2b/status/${details.fair_event_joined_b2b_id}`,
        obj
      );
      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: `Updated Successfully`,
        });
        setLoading(false);
        setReload((prev: Boolean) => !prev);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      Toast.fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
    }
  };
  return (
    <>
      <Divider />
      {details && (
        <CardContent>
          <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{
              mt: 1,
            }}
          >
            <Grid item xs={12}>
              <FormControl sx={{ mt: 2 }} required fullWidth>
                <InputLabel required id='demo-simple-select-label'>
                  B2B Join Status
                </InputLabel>
                <Select
                  id='outlined-required'
                  label='Status'
                  required
                  onChange={(e: any) => setStatus(e.target.value)}
                >
                  <MenuItem value={'approved'}>Approved</MenuItem>
                  <MenuItem value={'rejected'}>Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!isLoading ? (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update status
              </Button>
            ) : (
              <LoadingButton
                loading
                fullWidth
                loadingIndicator='Updating...'
                variant='outlined'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Updating...
              </LoadingButton>
            )}
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default UpdateEventB2BStatus;

import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import PaymentableItemTable from "./PaymentableItemTable";
import { IPaymentableItemList } from "src/Types/module/paymentableItem/itemList";
const PaymentableItemShow = () => {
  const [allPayments, setAllPayments] = useState<IPaymentableItemList[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);

  useEffect(() => {
    setLoading(true);
    try {
      axios
        .get(`${url}/api/atab/payment/get/all/paymentable/item`)
        .then(function (res) {
          if (res.data.success) {
            setAllPayments(res.data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(function (err) {
          setAllPayments([]);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  }, [reload]);

  return (
    <Card>
      <PaymentableItemTable
        allInvoice={allPayments}
        setReload={setReload}
        loading={loading}
      />
    </Card>
  );
};

export default PaymentableItemShow;

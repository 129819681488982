// export const url: string = "http://192.168.0.236:5005";
export const url: string = "https://bttf-server.toab.services";
export const imgUrl: string =
  "https://m360ict.s3.ap-south-1.amazonaws.com/toab";

//======== Auth context helper ========//
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAILED = "AUTH_USER_FAILED";
export const SUPPORT_CONVERSATION_MGS_ID = "SUPPORT_CONVERSATION_MGS_ID";

// export const SET_SIGNUP_DATA = 'SET_SIGNUP_DATA';

// export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const MODAL_SHOW = "MODAL_SHOW";
export const MODAL_HIDE = "MODAL_HIDE";

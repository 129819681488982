import { ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
} from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import MeetingTableRow from "./MeetingTableRow";
import { ImemberMeeting } from "src/Types/module/meeting/memberMeeting";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";

const applyPagination = (
  allMembers: ImemberMeeting[],
  page: number,
  limit: number
): ImemberMeeting[] => {
  return allMembers.slice(page * limit, page * limit + limit);
};

const MeetingTable = () => {
  const [allMembers, setAllMembers] = useState<ImemberMeeting[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [status, setStatus] = useState<string>("all");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useAuthContext();

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        let uri: string;
        if (user.user_admin_id) {
          uri = `${url}/api/member/meeting/get/all/meeting/or/by-status/${status}`;
        }
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllMembers(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [status]);

  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "active",
      name: "Active",
    },
    {
      id: "upcoming",
      name: "Upcoming",
    },
    {
      id: "rejected",
      name: "Rejected",
    },
  ];
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const paginatedMembers = applyPagination(allMembers, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box display="flex" justifyContent="space-between">
            <FormControl sx={{ width: "150px" }} variant="filled">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                autoWidth
              >
                {statusOptions &&
                  statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        }
        title="Meeting List"
      />
      <Divider />
      <TableContainer>
        <Table sx={{ position: "relative", minHeight: "250px" }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Meeting Title</TableCell>
              <TableCell>Meeting Date</TableCell>
              <TableCell>Meeting Time</TableCell>
              <TableCell>Meeting Creator ID</TableCell>
              <TableCell>Meeting Creator Name</TableCell>
              <TableCell>Meeting Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoader />
          ) : paginatedMembers.length ? (
            <TableBody>
              {paginatedMembers.map((allMembers: ImemberMeeting) => (
                <MeetingTableRow
                  key={allMembers.member_meeting_id}
                  allMembers={allMembers}
                />
              ))}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={allMembers.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default MeetingTable;

import { ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
} from "@mui/material";

import { Imembers } from "src/Types/module/member/memberTypes";
import { useDebounce } from "src/hooks/useDebounce";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import MemberTableRow from "./MemberTableRow";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";

const applyPagination = (
  allMembers: Imembers[],
  page: number,
  limit: number
): Imembers[] => {
  return allMembers.slice(page * limit, page * limit + limit);
};

const MemberTable = () => {
  const [allMembers, setAllMembers] = useState<Imembers[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchCompanyVal, setSearchCompanyVal] = useState<string>("");
  const [status, setStatus] = useState<string>("all");
  const searchQuery = useDebounce(searchCompanyVal, 1000);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        if (!searchQuery) {
          let uri: string;
          uri = `${url}/api/atab/user-member/get/all/by/zone/status/for-admin/all/${status}`;
          const { data } = await axios.get(uri);
          if (data.success) {
            setAllMembers(data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          const { data } = await axios.get(
            `${url}/api/atab/user-member/search-member/by-company/${searchQuery}`
          );

          if (data.success) {
            setAllMembers(data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [searchQuery, status]);

  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "active",
      name: "Active",
    },
    {
      id: "pending",
      name: "Pending",
    },
    {
      id: "rejected",
      name: "Rejected",
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedMembers = applyPagination(allMembers, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box display="flex" justifyContent="space-between">
            <Box width={280}>
              <TextField
                id="outlined-basic"
                label="Search Company"
                variant="filled"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchCompanyVal(e.target.value)
                }
              />
            </Box>
            <FormControl sx={{ width: "150px" }} variant="filled">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                autoWidth
              >
                {statusOptions &&
                  statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        }
        title="All Members"
      />
      <Divider />
      <TableContainer>
        <Table sx={{ position: "relative", minHeight: "250px" }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Account Status</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>info Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoader />
          ) : paginatedMembers.length ? (
            <TableBody>
              {paginatedMembers.map((allMembers) => {
                return (
                  <MemberTableRow
                    allMembers={allMembers}
                    key={allMembers.user_member_id}
                  />
                );
              })}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={allMembers.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default MemberTable;

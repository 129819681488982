import { Label, Visibility } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Toaster from "src/Toaster/Toaster";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Link } from "react-router-dom";
import {
  IPaymentableItemList,
  IPaymentableTableRow,
} from "src/Types/module/paymentableItem/itemList";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

const PaymentableItemTableRow = ({
  allInvoice,
  setReload,
}: IPaymentableTableRow) => {
  const Toast = Toaster();
  const theme = useTheme();
  const { user } = useAuthContext();

  const [invoiceData, setInvoiceData] =
    useState<IPaymentableItemList>(allInvoice);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setInvoiceData(allInvoice);
  }, [allInvoice]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (itemId: any) => {
    if (invoiceData.member_paymentable_item_id && user.user_admin_id) {
      try {
        const { data } = await axios.delete(
          `${url}/api/atab/payment/delete/paymentable/item/${invoiceData.member_paymentable_item_id}`
        );

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "Paymentable item succesfully deleted",
          });
          setReload((prev: Boolean) => !prev);
          setOpen(false);
        }
      } catch (err) {
        err;
        Toast.fire({
          icon: "error",
          title: `Paymentable item doesn't delete`,
        });
      }
    }
  };

  return (
    <>
      {/* <TableCell>{invoiceData.atab_paymentable_item_name}</TableCell> */}

      <TableRow hover>
        <TableCell>{invoiceData.member_paymentable_item_id}</TableCell>
        <TableCell>{invoiceData.member_paymentable_item_name}</TableCell>
        <TableCell>{invoiceData.member_paymentable_item_bdt_amount}</TableCell>
        <TableCell>{invoiceData.member_paymentable_item_usd_amount}</TableCell>
        <TableCell>
          <Tooltip title="View" arrow>
            <Link
              to={`/paymentable-item/list/${invoiceData.member_paymentable_item_id}`}
            >
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <Visibility fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete Order" arrow>
            <IconButton
              onClick={handleClickOpen}
              sx={{
                "&:hover": { background: theme.colors.error.lighter },
                color: theme.palette.error.main,
              }}
              color="inherit"
              size="small"
            >
              <DeleteTwoToneIcon fontSize="small" />
              {/* Delele Confirmation modal  */}
            </IconButton>
          </Tooltip>
          <DeleteConfirmation
            open={open}
            handleClose={handleClose}
            text="Are you want delete this paymentable item?"
            handleDeleteFun={handleDelete}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default PaymentableItemTableRow;

import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import FairStallTable from "./FairStallTable";
import { IFairStallList } from "src/Utils/type";

const FairStallShow = () => {
  const [allAdmins, setAllAdmins] = useState<IFairStallList[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>("all");
  const [secStatus, setSecStatus] = useState<string>("all");
  const [reload, setReload] = useState<Boolean>(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(
            `${url}/api/fair/stall/get/all/status/hall-name/${status}/${secStatus}`
          )
          .then(function (res) {
            if (res.data.success) {
              setAllAdmins(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllAdmins([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status, reload, secStatus]);

  return (
    <>
      <Card>
        <FairStallTable
          loading={loading}
          allAdmin={allAdmins}
          setStatus={setStatus}
          setSecStatus={setSecStatus}
          secStatus={secStatus}
          status={status}
          setReload={setReload}
        />
      </Card>
    </>
  );
};

export default FairStallShow;

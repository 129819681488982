import React, { useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { IFairAllEvents } from 'src/Types/module/FairEvent/FairEventType';
import SuspenseLoader from '../SuspenseLoader';
import EventTableRow from './EventTableRow';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';

const applyPagination = (
  allMembers: IFairAllEvents[],
  page: number,
  limit: number
): IFairAllEvents[] => {
  return allMembers.slice(page * limit, page * limit + limit);
};

const EventsTable = () => {
  const [allEvents, setAllEvents] = useState<IFairAllEvents[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('upcoming');
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        if (searchValue) {
          const { data } = await axios.get(
            `${url}/api/fair/event/get/${searchValue}/${status}`
          );
          if (data.success) {
            setAllEvents(data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else {
          const { data } = await axios.get(
            `${url}/api/fair/event/get/all/${status}`
          );
          if (data.success) {
            setAllEvents(data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status, searchValue]);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };
  const handleLimitChange = (event: any): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedMembers = applyPagination(allEvents, page, limit);
  return (
    <>
      <Card>
        <CardHeader
          action={
            <Box display='flex' justifyContent='space-between'>
              <Box width={280}>
                <TextField
                  id='outlined-basic'
                  label='Search by event name'
                  variant='filled'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchValue(e.target.value)
                  }
                />
              </Box>
              <FormControl sx={{ width: '150px' }} variant='filled'>
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label='Status'
                  autoWidth
                >
                  <MenuItem value='all'>All</MenuItem>
                  <MenuItem value='upcoming'>Upcoming</MenuItem>
                  <MenuItem value='ended'>Ended</MenuItem>
                  <MenuItem value='rejected'>Cancel</MenuItem>
                </Select>
              </FormControl>
            </Box>
          }
          title='All Events'
        />
        <Divider />
        <TableContainer>
          <Table sx={{ position: 'relative', minHeight: '250px' }}>
            <TableHead>
              <TableRow>
                <TableCell align='center'>ID</TableCell>
                <TableCell align='center'>Event title</TableCell>
                <TableCell align='center'>Event Venue</TableCell>
                <TableCell align='center'>Event Date</TableCell>
                <TableCell align='center'>Event Photo</TableCell>
                <TableCell align='center'>Action</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableLoader />
            ) : paginatedMembers.length ? (
              <TableBody>
                {allEvents.map((sEvent) => {
                  return (
                    <EventTableRow key={sEvent.event_id} sEvent={sEvent} />
                  );
                })}
              </TableBody>
            ) : (
              <TableNotFound />
            )}
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component='div'
            count={allEvents.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
      </Card>
    </>
  );
};

export default EventsTable;

import { Button, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { LoadingButton } from '@mui/lab';

function MeetingUpdateForm({ singleInvoiceDetails, handleEditModeChange }) {
  const { register, handleSubmit, reset } = useForm();
  const Toast = Toaster();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [isSuccess, setSuccess] = useState(false);
  const { user } = useAuthContext();
  const [timeValue, setTimeValue] = useState(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleDateChange = (event: any) => {
    setSelectedDate(event);
  };
  const handleChange = (newValue: React.SyntheticEvent<unknown>) => {
    setTimeValue(newValue);
  };

  const onSubmit = async (inputs: any) => {
    setIsLoading(true);
    if (
      (user.user_admin_id &&
        timeValue &&
        singleInvoiceDetails.member_meeting_time &&
        singleInvoiceDetails.member_meeting_id,
      singleInvoiceDetails.member_meeting_date &&
        singleInvoiceDetails.member_meeting_img)
    ) {
      const bodyFormData = new FormData();

      for (const key in inputs) {
        bodyFormData.append(key, inputs[key]);
      }
      bodyFormData.append(
        'member_meeting_time',
        timeValue.toISOString().slice(11, 19)
      );
      bodyFormData.append(
        'member_meeting_date',
        selectedDate.toISOString().slice(0, 10)
      );
      bodyFormData.append(
        'member_meeting_updated_by',
        user.user_admin_id.toString()
      );
      bodyFormData.append(
        'member_meeting_id',
        singleInvoiceDetails.member_meeting_id
      );
      bodyFormData.append(
        'member_meeting_img',
        uploadFile || singleInvoiceDetails.member_meeting_img
      );

      try {
        const { data } = await axios.put(
          `${url}/api/member/meeting/update/for-admin`,
          bodyFormData
        );

        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Updated Successfully`,
          });
          setIsLoading(false);
          setSuccess(true);
        }
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleEditModeChange();
    }
  }, [isSuccess]);

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '35ch' },
      }}
      noValidate
      autoComplete='off'
    >
      <Grid
        container
        sx={{ paddingLeft: '40px' }}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <TextField
          label='Member meeting title:'
          id='filled-size-small'
          defaultValue={singleInvoiceDetails.member_meeting_title}
          variant='filled'
          size='small'
          type='text'
          {...register('member_meeting_title')}
        />
        <TextField
          label='Member meeting detail:'
          id='filled-size-small'
          defaultValue={singleInvoiceDetails.member_meeting_detail}
          variant='filled'
          size='small'
          type='text'
          {...register('member_meeting_detail')}
        />
        <TextField
          label='Member meeting place:'
          id='filled-size-small'
          defaultValue={singleInvoiceDetails.member_meeting_place}
          variant='filled'
          size='small'
          type='text'
          {...register('member_meeting_place')}
        />
        <TextField
          label='Member meeting remark:'
          id='filled-size-small'
          defaultValue={singleInvoiceDetails?.member_meeting_remark}
          variant='filled'
          size='small'
          type='text'
          {...register('member_meeting_remark')}
        />

        <TextField
          id='filled-size-small'
          variant='filled'
          type='file'
          label='Upload a meeting image'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e: any) => setUploadFile(e.target.files[0])}
        />
        {/* <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label='Update meeting date'
            inputFormat='MM/DD/YYYY'
            value={selectedDate || singleInvoiceDetails.member_meeting_date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label='Update meeting time'
            value={
              timeValue ||
              moment(
                singleInvoiceDetails.member_meeting_time,
                'HH:mm'
              ).toISOString()
            }
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider> */}
      </Grid>
      {!isLoading ? (
        <Button
          type='submit'
          variant='contained'
          disableElevation
          sx={{
            margin: 2,
          }}
        >
          Update meeting
        </Button>
      ) : (
        <LoadingButton
          loading
          loadingIndicator='Updating...'
          variant='outlined'
        >
          Updating...
        </LoadingButton>
      )}
    </Box>
  );
}

export default MeetingUpdateForm;

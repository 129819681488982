import { Visibility } from '@mui/icons-material';
import {
  Button,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Box } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { url } from 'src/Helpers/utils/Constant';
import { ImemberInvoice } from 'src/Types/module/common/commonTypes';
import { getStatusLabel } from 'src/Utils/Combined';

const CompanyInvoices = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();
  memberId;
  const [memberInvoice, setMemberInvoice] = useState<ImemberInvoice[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/atab/invoice/get/all/by/user-member/for-admin/status/${memberId}/all`
        );
        if (data.success) {
          setMemberInvoice(data.data);
          setLoading(false);
        }
      } catch (error) {
        error?.response?.data?.message;
        setLoading(false);
      }
    })();
    setLoading(false);
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [memberId]);

  return (
    <>
      {!loading ? (
        <Container maxWidth='lg'>
          <Typography
            sx={{ textDecoration: 'underline' }}
            mt={8}
            mb={3}
            color='text.'
            align='center'
            variant='h4'
          >
            Single member invoice Items
          </Typography>
          <Box textAlign='right'>
            <Button onClick={() => navigate(-1)}>Go back</Button>
          </Box>
          <TableContainer sx={{ marginBottom: 10 }}>
            <Table>
              <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
                <TableRow>
                  <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                    Invoice ID
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.white[100] }}
                    align='center'
                  >
                    Invoice issue date
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.white[100] }}
                    align='center'
                  >
                    Company name
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.white[100] }}
                    align='center'
                  >
                    Total Amount
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.white[100] }}
                    align='center'
                  >
                    Audit trail
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.white[100] }}
                    align='center'
                  >
                    Payment method
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.white[100] }}
                    align='center'
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.white[100] }}
                    align='center'
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {memberInvoice &&
                  memberInvoice.map((details: ImemberInvoice) => (
                    <TableRow hover key={details.atab_payment_invoice_id}>
                      <TableCell>{details.atab_payment_invoice_id}</TableCell>
                      <TableCell>
                        {moment(details.atab_payment_invoice_issue_date)
                          .utc()
                          .format('DD-MM-YYYY')}
                      </TableCell>

                      {/* <TableCell>{details.user_member_company_name}</TableCell> */}
                      <TableCell>
                        {details.atab_payment_invoice_total_amount}
                      </TableCell>
                      {/* {details.updated_by_admin_name ? (
                        <TableCell>{details?.updated_by_admin_name}</TableCell>
                      ) : (
                        <TableCell>Null</TableCell>
                      )} */}
                      {details.atab_payment_invoice_payment_method ? (
                        <TableCell>
                          {details?.atab_payment_invoice_payment_method}
                        </TableCell>
                      ) : (
                        <TableCell>Null</TableCell>
                      )}
                      <TableCell>
                        {getStatusLabel(details.atab_payment_invoice_status)}
                      </TableCell>
                      <TableCell>
                        <Tooltip title='View' arrow>
                          <Link
                            to={`/invoices/transactions/${details.atab_payment_invoice_id}`}
                          >
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter,
                                },
                                color: theme.palette.primary.main,
                              }}
                              color='inherit'
                              size='small'
                            >
                              <Visibility fontSize='small' />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      ) : (
        <GlobalLoader />
      )}
    </>
  );
};

export default CompanyInvoices;

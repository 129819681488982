import { TableCell, TableHead, TableRow } from "@mui/material";
import SuspenseLoader from "src/components/SuspenseLoader";

export function TableLoader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={100}>
          <SuspenseLoader />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
export function TableNotFound() {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ fontSize: "24px", fontWeight: "bold" }}
          colSpan={100}
          align="center"
        >
          Not Found
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { url } from 'src/Helpers/utils/Constant';
import axios from 'axios';
import { IAllAttendVisitor } from 'src/Types/module/FairEvent/FairEventType';
import SuspenseLoader from '../SuspenseLoader';
import moment from 'moment';

const AllAttendVisitor = ({ eventId }: any) => {
  const theme = useTheme();
  const [allAttendVisitor, setAllAttendVisitor] = useState<IAllAttendVisitor[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/fair/event/get/all/attend-visitor/by/${eventId}`
        );

        if (data.success) {
          setAllAttendVisitor(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [eventId]);
  return (
    <>
      <TableContainer sx={{ marginBottom: 10 }}>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Attend Visitor ID
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Attend Visitor Name
              </TableCell>

              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Join Date
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Join At
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align='center'>
                  <SuspenseLoader />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {allAttendVisitor.length ? (
                  <>
                    {allAttendVisitor.map((details) => (
                      <TableRow hover key={details.fair_event_attend_id}>
                        <TableCell align='center'>
                          {details.fair_event_attend_id}
                        </TableCell>
                        <TableCell align='center'>
                          {details.visitor_first_name}{' '}
                        </TableCell>
                        <TableCell align='center'>
                          {moment(
                            details.fair_event_joined_visitor_join_date
                          ).format('L')}
                        </TableCell>
                        <TableCell align='center'>
                          {moment(details.fair_event_attend_at).format('LLL')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      align='center'
                      colSpan={4}
                      sx={{ fontSize: 20, pt: 4 }}
                    >
                      Not Data Found
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AllAttendVisitor;

import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  Tooltip,
  IconButton,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import B2BInvoiceTableRow from './B2BInvoiceTableRow';
import { SimCardDownload } from '@mui/icons-material';

const applyPagination = (allInvoice: any, page: number, limit: number): any => {
  return allInvoice.slice(page * limit, page * limit + limit);
};

const B2BInvoiceTable = ({ allAdmin, loading, setStatus, status }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allAdmin, page, limit);

  return (
    <Card>
      <CardHeader title=' All Buyer/Seller B2B invoice list' />

      <Divider />
      <TableContainer>
        <Table sx={{ position: 'relative', minHeight: '250px' }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Invoice issue date</TableCell>
              <TableCell>Total amount</TableCell>
              <TableCell>Invoice status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableLoader />
          ) : allAdmin.length ? (
            <TableBody>
              {paginatedInvoice.map((allAdmin: any) => {
                return (
                  <B2BInvoiceTableRow
                    key={allAdmin.b2b_invoice_id}
                    allAdmin={allAdmin}
                  />
                );
              })}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component='div'
          count={allAdmin.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default B2BInvoiceTable;

import { useState, useEffect } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { url } from 'src/Helpers/utils/Constant';
import axios from 'axios';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import EditIcon from '@mui/icons-material/Edit';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { getStatusLabel } from 'src/Utils/Combined';
import UpdateEventVisitorStatus from './UpdateEventVisitorStatus';
import UpdateEventB2BStatus from 'src/content/modules/B2B/singleB2B/UpdateEventB2BStatus';

const AllJoinB2B = ({ eventId }: any) => {
  const theme = useTheme();
  const [allB2BJoin, setAllB2BJoin] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<Boolean>(false);

  const [modalVisibilities, setModalVisibilities] = useState<boolean[]>([]);
  // Initialize the modal visibility array based on the number of rows
  useEffect(() => {
    setModalVisibilities(new Array(allB2BJoin.length).fill(false));
  }, [allB2BJoin]);

  // Function to toggle the modal visibility for a specific row
  const toggleModalVisibility = (index: number) => {
    const updatedVisibilities = [...modalVisibilities];
    updatedVisibilities[index] = !updatedVisibilities[index];
    setModalVisibilities(updatedVisibilities);
  };

  useEffect(() => {
    setLoading(true);

    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/fair/event/join/b2b?eventId=${eventId}`
        );
        if (data.success) {
          setAllB2BJoin(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [eventId, reload]);
  return (
    <>
      <TableContainer sx={{ marginBottom: 10 }}>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                B2B ID
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Name
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Email
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Organization
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Status
              </TableCell>

              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : (
              <>
                {allB2BJoin.length ? (
                  <>
                    {allB2BJoin?.map((details, index) => (
                      <TableRow hover key={details.fair_event_joined_b2b_id}>
                        <TableCell align='center'>
                          {details.fair_event_joined_b2b_id}
                        </TableCell>
                        <TableCell align='center'>
                          {details.b2b_first_name} {details.b2b_last_name}
                        </TableCell>
                        <TableCell align='center'>
                          {details.b2b_email ? details.b2b_email : 'N/A'}
                        </TableCell>
                        <TableCell align='center'>
                          {details.b2b_organization
                            ? details.b2b_organization
                            : 'N/A'}
                        </TableCell>

                        <TableCell align='center'>
                          {getStatusLabel(details.fair_event_joined_b2b_status)}
                        </TableCell>

                        <TableCell>
                          <Tooltip title='Edit'>
                            <IconButton
                              onClick={() => toggleModalVisibility(index)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <ModalsWrapper
                          showModal={modalVisibilities[index]}
                          setShowModal={(value) => {
                            const updatedVisibilities = [...modalVisibilities];
                            updatedVisibilities[index] = value;
                            setModalVisibilities(updatedVisibilities);
                          }}
                          modalData={{ title: 'Update Status' }}
                        >
                          <UpdateEventB2BStatus
                            details={details}
                            setReload={setReload}
                          />
                        </ModalsWrapper>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      align='center'
                      colSpan={4}
                      sx={{ fontSize: 20, pt: 4 }}
                    >
                      Not Data Found
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AllJoinB2B;

import { useState, useEffect } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { url } from 'src/Helpers/utils/Constant';
import axios from 'axios';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import EditIcon from '@mui/icons-material/Edit';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import UpdateSpeakerMaterials from 'src/content/modules/GuestAndSpecker/UpdateSpeakerMaterials';
import { getStatusLabel } from 'src/Utils/Combined';
import { IAllJoinVisitor } from 'src/Types/module/FairEvent/FairEventType';
import UpdateEventVisitorStatus from './UpdateEventVisitorStatus';

const AllJoinVisitor = ({ eventId }: any) => {
  const theme = useTheme();
  const [allVisitorJoin, setAllVisitorJoin] = useState<IAllJoinVisitor[]>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<Boolean>(false);

  const [modalVisibilities, setModalVisibilities] = useState<boolean[]>([]);
  // Initialize the modal visibility array based on the number of rows
  useEffect(() => {
    setModalVisibilities(new Array(allVisitorJoin.length).fill(false));
  }, [allVisitorJoin]);

  // Function to toggle the modal visibility for a specific row
  const toggleModalVisibility = (index: number) => {
    const updatedVisibilities = [...modalVisibilities];
    updatedVisibilities[index] = !updatedVisibilities[index];
    setModalVisibilities(updatedVisibilities);
  };

  useEffect(() => {
    setLoading(true);

    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/fair/event/get/all/registered-visitor/${eventId}/all`
        );

        if (data.success) {
          setAllVisitorJoin(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [eventId, reload]);

  return (
    <>
      <TableContainer sx={{ marginBottom: 10 }}>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Visitor ID
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Visitor Name
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Visitor Email
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Visitor Organization
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Status
              </TableCell>

              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : (
              <>
                {allVisitorJoin.length ? (
                  <>
                    {allVisitorJoin.map((details, index) => (
                      <TableRow
                        hover
                        key={details.fair_event_joined_visitor_id}
                      >
                        <TableCell align='center'>
                          {details.fair_event_joined_visitor_id}
                        </TableCell>
                        <TableCell align='center'>
                          {details.visitor_first_name}{' '}
                          {details.visitor_last_name}
                        </TableCell>
                        <TableCell align='center'>
                          {details.visitor_email
                            ? details.visitor_email
                            : 'N/A'}
                        </TableCell>
                        <TableCell align='center'>
                          {details.visitor_organization
                            ? details.visitor_organization
                            : 'N/A'}
                        </TableCell>

                        <TableCell align='center'>
                          {getStatusLabel(
                            details.fair_event_joined_visitor_status
                          )}
                        </TableCell>

                        <TableCell>
                          <Tooltip title='Edit'>
                            <IconButton
                              onClick={() => toggleModalVisibility(index)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <ModalsWrapper
                          showModal={modalVisibilities[index]}
                          setShowModal={(value) => {
                            const updatedVisibilities = [...modalVisibilities];
                            updatedVisibilities[index] = value;
                            setModalVisibilities(updatedVisibilities);
                          }}
                          modalData={{ title: 'Update Status' }}
                        >
                          <UpdateEventVisitorStatus
                            details={details}
                            setReload={setReload}
                          />
                        </ModalsWrapper>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableNotFound />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AllJoinVisitor;

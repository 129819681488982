import { IauthContextState } from 'src/components/Context/AuthContext/AuthTypes';
import {
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILED,
  SUPPORT_CONVERSATION_MGS_ID,
} from './../../Helpers/utils/Constant';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case AUTH_USER_SUCCESS:
      // (action.payload)
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_USER_FAILED:
      return { ...state, user: {} };

    // case SUPPORT_CONVERSATION_MGS_ID:
    //   return { ...state, support_conversation_msg_id: action.payload };
    default:
      throw new Error(`No matching action type - ${action.type}`);
  }
};

export default reducer;

import { Box, Card, CardContent, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import SuspenseLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { url } from 'src/Helpers/utils/Constant';
import { IsingleTrainee } from 'src/Types/module/courses/singleTrainee';
import OrganizationInfo from './TraineeMemberInfo/OrganizationInfo';
import ParticularsOwnerInfo from './TraineeMemberInfo/ParticularsOwnerInfo';

function SingleTrainee() {
  const { traineeId } = useParams();
  traineeId;
  const [traineeInfo, setTraineeInfo] = useState<IsingleTrainee>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/trainee/get/single/${traineeId}`
        );
        if (data.success) {
          setLoading(false);
          setTraineeInfo(data.data);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [traineeId]);
  return (
    <>
      <Helmet>
        <title>TOAB - Trainee Information</title>
      </Helmet>
      <Box>
        <Box>
          <Card>
            <CardContent>
              {loading ? (
                <SuspenseLoader />
              ) : (
                <Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <ParticularsOwnerInfo traineeInfo={traineeInfo} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <OrganizationInfo traineeInfo={traineeInfo} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default SingleTrainee;

import React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import { ICompanyInformationDetailsProps } from 'src/Types/module/member/memberTypes';
import { url } from 'src/Helpers/utils/Constant';

const TradeLicenseInfoDetails = ({
  companyInfo,
}: ICompanyInformationDetailsProps) => {
  const {
    member_organization_trade_licence_number,
    member_organization_trade_licence_date,
    member_organization_trade_licence_file,
    member_organization_tin_number,
    member_organization_tin_file,
  } = companyInfo || {};
  return (
    <Box className='bg-style yellowshade' mr={1}>
      <h2>Information organization trade</h2>
      <div className='mt-2 company-info'>
        <ul>
          <li>
            <span>Organization trade licence number</span>:{' '}
            <p>
              {member_organization_trade_licence_number
                ? member_organization_trade_licence_number
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Organization trade licence date</span>:{' '}
            <p>
              {member_organization_trade_licence_date
                ? moment(member_organization_trade_licence_date).format(
                    'MMM Do YY'
                  )
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Organization trade licence </span>:{' '}
            <p>
              {member_organization_trade_licence_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_trade_licence_file}`}>
                  {member_organization_trade_licence_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Organization tin number </span>:{' '}
            <p>
              {member_organization_tin_number
                ? member_organization_tin_number
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Organization trade licence date</span>:{' '}
            <p>
              {member_organization_tin_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_tin_file}`}>
                  {member_organization_tin_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default TradeLicenseInfoDetails;

import { Label, Visibility } from '@mui/icons-material';
import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Toaster from 'src/Toaster/Toaster';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import {
  Iinvoice,
  IinvoiceTableRow,
} from 'src/Types/module/invoice/invoiceTypes';
import { Link } from 'react-router-dom';
import {
  getStatusLabel,
  getStatusLabelInvoiceStatus,
} from 'src/Utils/Combined';

const InvoiceTableRow = ({ allInvoice }: IinvoiceTableRow) => {
  const Toast = Toaster();
  const theme = useTheme();

  const [invoiceData, setInvoiceData] = useState<Iinvoice>(allInvoice);

  useEffect(() => {
    setInvoiceData(allInvoice);
  }, [allInvoice]);

  const handleUpdate = async () => {
    // const body = {
    //   user_member_account_status: invoiceData.status,
    //   user_member_info_verified_status: invoiceData.infoStatus,
    //   user_member_payment_verified_status: invoiceData.paymentStatus,
    // };
    // (body);
    // try {
    //   const { data } = await axios.put(
    //     `${url}/api/atab/user-member/admin/update/member/${allMembers.user_member_id}`,
    //     body
    //   );
    //   ({ data });
    //   if (data.success) {
    //     Toast.fire({
    //       icon: 'success',
    //       title: 'Member succesfully updated',
    //     });
    //   }
    // } catch (err) {
    //   (err);
    //   Toast.fire({
    //     icon: 'error',
    //     title: `Update Failed`,
    //   });
    // }
  };

  ({ invoiceData });

  return (
    <>
      {invoiceData && (
        <TableRow hover key={invoiceData.member_payment_invoice_id}>
          <TableCell>{invoiceData.member_payment_invoice_id}</TableCell>
          <TableCell>
            {moment(invoiceData.member_payment_invoice_issue_date)
              .utc()
              .format('DD-MM-YYYY')}
          </TableCell>

          <TableCell>{invoiceData.user_member_company_name}</TableCell>
          <TableCell>
            {invoiceData.member_payment_invoice_total_amount}
          </TableCell>
          <TableCell>
            {getStatusLabelInvoiceStatus(
              invoiceData.member_payment_invoice_status
            )}
            {/* {invoiceData.atab_payment_invoice_status} */}
          </TableCell>
          {/* <TableCell>
            {invoiceData.atab_payment_invoice_payment_method}
          </TableCell> */}

          <TableCell>
            <Tooltip title='View' arrow>
              <Link to={`${invoiceData.member_payment_invoice_id}`}>
                <IconButton
                  sx={{
                    '&:hover': {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color='inherit'
                  size='small'
                >
                  <Visibility fontSize='small' />
                </IconButton>
              </Link>
            </Tooltip>
            {/* <Tooltip title='Delete Order' arrow>
            <IconButton
              sx={{
                '&:hover': { background: theme.colors.error.lighter },
                color: theme.palette.error.main,
              }}
              color='inherit'
              size='small'>
              <DeleteTwoToneIcon fontSize='small' />
            </IconButton>
          </Tooltip> */}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default InvoiceTableRow;

import React from 'react';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IFairAllEvents } from 'src/Types/module/FairEvent/FairEventType';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { imgUrl } from 'src/Helpers/utils/Constant';

interface IProps {
  sEvent: IFairAllEvents;
}

const EventTableRow = ({ sEvent }: IProps) => {
  const theme = useTheme();
  return (
    <>
      <TableRow hover key={sEvent.event_id}>
        <TableCell align='center'>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {sEvent.event_id}
          </Typography>
        </TableCell>
        <TableCell align='center'>{sEvent.event_title}</TableCell>
        <TableCell align='center'>{sEvent.event_venu}</TableCell>
        <TableCell align='center'>
          {moment(sEvent.event_date).format('MMM Do YY')}
        </TableCell>
        <TableCell align='center'>
          <img
            width={30}
            height={30}
            style={{
              borderRadius: '50%',
              marginRight: '10px',
            }}
            src={
              sEvent.event_photo
                ? `${imgUrl}/event_files/${sEvent.event_photo}`
                : '/static/images/avatars/avatar2.png'
            }
            alt=''
          />
        </TableCell>

        <TableCell align='center'>
          <Link to={`/fair-event/all-event-list/${sEvent.event_id.toString()}`}>
            <Tooltip title='view' arrow>
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color='inherit'
                size='small'
              >
                <VisibilityIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EventTableRow;

import React from 'react';
import { Box } from '@mui/material';
import { ICompanyInformationDetailsProps } from 'src/Types/module/member/memberTypes';
import { url } from 'src/Helpers/utils/Constant';

const OtherbusinessLicenseInfoDetails = ({
  companyInfo,
}: ICompanyInformationDetailsProps) => {
  const {
    member_organization_hajj_licence,
    member_organization_hajj_licence_file,
    member_organization_umrah_licence,
    member_organization_umrah_licence_file,
    member_organization_recruting_licence,
    member_organization_recruting_licence_file,
    member_organization_inbound,
    member_organization_outbound,
  } = companyInfo || {};

  return (
    <Box className='bg-style three'>
      <h2>Business license information</h2>
      <div className='mt-2 company-info'>
        <ul>
          <li>
            <span>Hajj licence</span>:{' '}
            <p>
              {member_organization_hajj_licence
                ? member_organization_hajj_licence === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Hajj licence document</span>:{' '}
            <p>
              {member_organization_hajj_licence_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_hajj_licence_file}`}>
                  {member_organization_hajj_licence_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Umrah licence</span>:{' '}
            <p>
              {member_organization_umrah_licence
                ? member_organization_umrah_licence === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Umrah licence document</span>:{' '}
            <p>
              {member_organization_umrah_licence_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_umrah_licence_file}`}>
                  {member_organization_umrah_licence_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Recruting licence</span>:{' '}
            <p>
              {member_organization_recruting_licence
                ? member_organization_recruting_licence === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Recruting licence document</span>:{' '}
            <p>
              {member_organization_recruting_licence_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_recruting_licence_file}`}>
                  {member_organization_recruting_licence_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Involved in tourism activities inbound</span>:{' '}
            <p>
              {member_organization_inbound
                ? member_organization_inbound === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Involved in tourism activities outbound</span>:{' '}
            <p>
              {member_organization_outbound
                ? member_organization_outbound === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default OtherbusinessLicenseInfoDetails;

import { useContext } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  ToggleButton,
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/components/Context/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router';
const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <HeaderWrapper
      display='flex'
      alignItems='center'
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`,
      }}
    >
      <Box display='flex' alignItems='first' gap={1}>
        <ToggleButton onClick={() => navigate(-1)} value='check'>
          <ArrowBackIosNewIcon />
        </ToggleButton>
        <ToggleButton onClick={() => navigate(1)} value='check'>
          <ArrowForwardIosIcon />
        </ToggleButton>
      </Box>
      <Stack
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
        alignItems='center'
        spacing={2}
      >
        {/* <HeaderMenu /> */}
      </Stack>
      <HeaderUserbox />
      <Box
        component='span'
        sx={{
          ml: 2,
          display: { lg: 'none', xs: 'inline-block' },
        }}
      >
        <Tooltip arrow title='Toggle Menu'>
          <IconButton color='primary' onClick={toggleSidebar}>
            {!sidebarToggle ? (
              <MenuTwoToneIcon fontSize='small' />
            ) : (
              <CloseTwoToneIcon fontSize='small' />
            )}
          </IconButton>
        </Tooltip>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;

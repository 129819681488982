import React from "react";

import { Box } from "@mui/material";
import {
  IsingleTrainee,
  ItraineeInfo,
} from "src/Types/module/courses/singleTrainee";
import { url } from "src/Helpers/utils/Constant";
import moment from "moment";

const ParticularsOwnerInfo = ({
  traineeInfo,
}: {
  traineeInfo: IsingleTrainee;
}) => {
  const {
    user_training_trainee_id,
    user_training_trainee_name,
    user_training_trainee_user_member_id,
    user_training_trainee_email,
    user_training_trainee_phone,
    user_training_trainee_photo,
    user_training_trainee_birthdate,
    user_training_trainee_gender,
    user_training_trainee_fathers_name,
    user_training_trainee_mothers_name,
    user_training_trainee_present_address,
    user_training_trainee_payment_status,
    user_training_trainee_created_at,
    user_training_trainee_account_status

  } = traineeInfo || {};
  return (
    <Box className="bg-style one">
      <h2>Trainee information</h2>
      <div className="mt-2 company-info">
        <ul>
          <li>
            <span>Trainee ID</span>:{" "}
            <p>
              {user_training_trainee_id ? user_training_trainee_id : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee Name</span>:{" "}
            <p>
              {user_training_trainee_name ? user_training_trainee_name : "Null"}
            </p>
          </li>
          <li>
            <span>User ID</span>:{" "}
            <p>
              {user_training_trainee_user_member_id
                ? user_training_trainee_user_member_id
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee Phone Number</span>:{" "}
            <p>
              {user_training_trainee_phone
                ? user_training_trainee_phone
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee photo</span>:{" "}
            <p>
              {user_training_trainee_photo ? (
                <a
                  href={`${url}/api/download/file/training_trainee_files/${user_training_trainee_photo}`}
                >
                  {user_training_trainee_photo}
                </a>
              ) : (
                "Null"
              )}
            </p>
          </li>
          <li>
            <span>Trainee Email</span>:{" "}
            <p>
              {user_training_trainee_email
                ? user_training_trainee_email
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee Gender</span>:{" "}
            <p>
              {user_training_trainee_gender
                ? user_training_trainee_gender
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee birthdate</span>:{" "}
            <p>
              {user_training_trainee_birthdate
                ? moment(user_training_trainee_birthdate).format("MMM Do YY")
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee full address</span>:{" "}
            <p>
              {user_training_trainee_present_address
                ? user_training_trainee_present_address
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee mother name</span>:{" "}
            <p>
              {user_training_trainee_mothers_name
                ? user_training_trainee_mothers_name
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee father name</span>:{" "}
            <p>
              {user_training_trainee_fathers_name
                ? user_training_trainee_fathers_name
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee Payment status</span>:{" "}
            <p>
              {user_training_trainee_payment_status
                ? user_training_trainee_payment_status
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee account status</span>:{" "}
            <p>
              {user_training_trainee_account_status
                ? user_training_trainee_account_status
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee create date</span>:{" "}
            <p>
              {user_training_trainee_created_at
                ? moment(user_training_trainee_created_at).format(
                  'MMM Do YY'
                )
                : 'Null'}
            </p>
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default ParticularsOwnerInfo;

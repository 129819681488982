import { Card, Grid } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { url } from 'src/Helpers/utils/Constant';
import { IFairMemberApplication } from 'src/Types/module/fair/fairSingleMemberApplication';
import { ISingleFair } from 'src/Types/module/fair/singlefair';
import SingleFairApplicationTable from './SingleFairApplicationTable';
import SingleFairInfo from './SingleFairInformation';

const SingleFairApplicationPage = () => {
  const [singleInvoiceInfo, setSingleInvoiceInfo] = useState<ISingleFair>();
  const [fairMemberApplication, setFairMemberApplication] = useState<
    IFairMemberApplication[]
  >([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('all');
  const { singleFairId } = useParams();

  useEffect(() => {
    if (singleFairId) {
      (async function () {
        try {
          setLoading(true);
          const urls = [
            `${url}/api/fair/get/single-fair/${singleFairId}`,
            `${url}/api/fair/get/all/fair/application/by/fair-id/status/${singleFairId}/${status}`,
          ];
          Promise.all(
            urls.map((url) => fetch(url).then((response) => response.json()))
          ).then((data) => {
            data.length;

            if (data.length) {
              setSingleInvoiceInfo(data[0].data);
              setFairMemberApplication(data[1].data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          });
        } catch (error) {
          setSingleInvoiceInfo(null);
          setLoading(false);
        }
      })();
    }
  }, [singleFairId, reload, status]);

  return (
    <div>
      <Helmet>
        <title>ATAB - Single fair member information</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'
          spacing={3}
        >
          <Grid item xs={12}>
            {singleInvoiceInfo && (
              <SingleFairInfo fairInfo={singleInvoiceInfo} />
            )}
            <Card>
              <SingleFairApplicationTable
                loading={loading}
                allAdmin={fairMemberApplication}
                setStatus={setStatus}
                status={status}
                setReload={setReload}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SingleFairApplicationPage;

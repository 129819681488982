import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { Helmet } from 'react-helmet-async';
import FairMemberTable from './FairMemberTable';
import { IFairMember } from 'src/Types/module/fair/fairMember';

const FairMemberShow = () => {
  const [fairAllMember, setFairAllMember] = useState<IFairMember[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('all');

  useEffect(() => {
    const controller = new AbortController();

    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/fair/member/get/all/${status}`)
          .then(function (res) {
            if (res.data.success) {
              setFairAllMember(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setFairAllMember([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [status]);

  return (
    <>
      <Helmet>
        <title>BTTF - Fair member list</title>
      </Helmet>
      <Card>
        <FairMemberTable
          loading={loading}
          fairAllMember={fairAllMember}
          setStatus={setStatus}
          status={status}
        />
      </Card>
    </>
  );
};

export default FairMemberShow;

import React from "react";
import Box from "@mui/material/Box";
import moment from "moment";

import { IsingleTrainee } from "src/Types/module/courses/singleTrainee";
import { url } from "src/Helpers/utils/Constant";
const OrganizationInfo = ({ traineeInfo }: { traineeInfo: IsingleTrainee }) => {
  const {
    user_training_trainee_info_source,
    user_training_trainee_guardian_nid_back_file,
    user_training_trainee_guardian_nid_number,
    user_training_trainee_last_academic_certificate_file,
    user_training_trainee_nid_front_file,
    user_training_trainee_nid_back_file,
    user_training_trainee_cv_file,
    user_training_trainee_nationality,
    user_training_trainee_nid_number,
    user_training_trainee_religion,
    user_training_trainee_signeture_file,
    user_training_trainee_occupation,
    user_training_trainee_last_academic_qualification,
    user_training_trainee_guardian_nid_front_file,
    user_training_trainee_guardian_name,
    user_training_trainee_guardian_relation,
    user_training_trainee_guardian_address,
    user_training_trainee_guardian_mobile,

  } = traineeInfo || {};
  return (
    <Box className="bg-style" mr={1}>
      <Box className="mt-2 company-info">
        <h2>Trainee information documents</h2>
        <ul>
          <li>
            <span>Nationality</span>:{" "}
            <p>
              {user_training_trainee_nationality
                ? user_training_trainee_nationality
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee NID Number</span>:{" "}
            <p>
              {user_training_trainee_nid_number
                ? user_training_trainee_nid_number
                : "Null"}
            </p>
          </li>

          <li>
            <span>NID back side file</span>:{" "}
            <p>
              {user_training_trainee_nid_front_file ? (
                <a
                  href={`${url}/api/download/file/training_trainee_files/${user_training_trainee_nid_front_file}`}
                >
                  {user_training_trainee_nid_front_file}
                </a>
              ) : (
                "Null"
              )}
            </p>
          </li>
          <li>
            <span>NID back side file</span>:{" "}
            <p>
              {user_training_trainee_nid_back_file ? (
                <a
                  href={`${url}/api/download/file/training_trainee_files/${user_training_trainee_nid_back_file}`}
                >
                  {user_training_trainee_nid_back_file}
                </a>
              ) : (
                "Null"
              )}
            </p>
          </li>
          <li>
            <span>Trainee Information Source</span>:{" "}
            <p>
              {user_training_trainee_info_source
                ? user_training_trainee_info_source
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee CV file</span>:{" "}
            <p>
              {user_training_trainee_cv_file ? (
                <a
                  href={`${url}/api/download/file/training_trainee_files/${user_training_trainee_cv_file}`}
                >
                  {user_training_trainee_cv_file}
                </a>
              ) : (
                "Null"
              )}
            </p>
          </li>
          <li>
            <span>Trainee last academic qualification</span>:{" "}
            <p>
              {user_training_trainee_last_academic_qualification
                ? user_training_trainee_last_academic_qualification
                : "Null"}
            </p>
          </li>

          <li>
            <span>Trainee last academic file</span>:{" "}
            <p>
              {user_training_trainee_last_academic_certificate_file ? (
                <a
                  href={`${url}/api/download/file/training_trainee_files/${user_training_trainee_last_academic_certificate_file}`}
                >
                  {user_training_trainee_last_academic_certificate_file}
                </a>
              ) : (
                "Null"
              )}
            </p>
          </li>
          <li>
            <span>Trainee last academic file</span>:{" "}
            <p>
              {user_training_trainee_signeture_file ? (
                <a
                  href={`${url}/api/download/file/training_trainee_files/${user_training_trainee_signeture_file}`}
                >
                  {user_training_trainee_signeture_file}
                </a>
              ) : (
                "Null"
              )}
            </p>
          </li>
          <li>
            <span>Trainee Occupation</span>:{" "}
            <p>
              {user_training_trainee_occupation
                ? user_training_trainee_occupation
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee Religion</span>:{" "}
            <p>
              {user_training_trainee_religion
                ? user_training_trainee_religion
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee guardian name</span>:{" "}
            <p>
              {user_training_trainee_guardian_name
                ? user_training_trainee_guardian_name
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee guardian address</span>:{" "}
            <p>
              {user_training_trainee_guardian_address
                ? user_training_trainee_guardian_address
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee guardian mobile</span>:{" "}
            <p>
              {user_training_trainee_guardian_mobile
                ? user_training_trainee_guardian_mobile
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee guardian relation</span>:{" "}
            <p>
              {user_training_trainee_guardian_relation
                ? user_training_trainee_guardian_relation
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee guardian NID number</span>:{" "}
            <p>
              {user_training_trainee_guardian_nid_number
                ? user_training_trainee_guardian_nid_number
                : "Null"}
            </p>
          </li>
          <li>
            <span>Trainee guardian NID front file</span>:{" "}
            <p>
              {user_training_trainee_guardian_nid_front_file ? (
                <a
                  href={`${url}/api/download/file/training_trainee_files/${user_training_trainee_guardian_nid_front_file}`}
                >
                  {user_training_trainee_guardian_nid_front_file}
                </a>
              ) : (
                "Null"
              )}
            </p>
          </li>
          <li>
            <span>Trainee guardian NID back file</span>:{" "}
            <p>
              {user_training_trainee_guardian_nid_back_file ? (
                <a
                  href={`${url}/api/download/file/training_trainee_files/${user_training_trainee_guardian_nid_back_file}`}
                >
                  {user_training_trainee_guardian_nid_back_file}
                </a>
              ) : (
                "Null"
              )}
            </p>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default OrganizationInfo;

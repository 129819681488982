import React, { useContext, useReducer, useState, useEffect } from 'react';
import * as jose from 'jose';
import { IauthContextState } from './AuthTypes';
import {
  AUTH_USER_FAILED,
  AUTH_USER_SUCCESS,
} from 'src/Helpers/utils/Constant';
import reducer from 'src/Reducers/AuthReducer/AuthReducer';
import { parseCookies } from 'nookies';

const initialState: IauthContextState = {
  user: {},
  support_conversation_msg_id: 0,
};

const AuthContext = React.createContext(initialState);
const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const { toab_adm } = parseCookies();
      if (toab_adm) {
        setIsLoading(true);
        try {
          const { payload } = await jose.jwtVerify(
            toab_adm,
            new TextEncoder().encode('AdmTObhDm&d3tkJ*j9ay+f7%6V4XpVABmiN')
          );

          dispatch({ type: AUTH_USER_SUCCESS, payload });
          setIsLoading(false);
        } catch (error) {
          error;
          dispatch({ type: AUTH_USER_FAILED });
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <AuthContext.Provider
        value={{ ...state, dispatch, isLoading, setIsLoading }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthContextProvider, useAuthContext };

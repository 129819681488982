import React from 'react';
import { Card } from '@mui/material';
import MemberTable from './MemberTable';

const MemberShow = () => {
  return (
    <>
      <Card>
        <MemberTable />
      </Card>
    </>
  );
};

export default MemberShow;

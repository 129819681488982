import { TableCell, TableRow, Typography } from '@mui/material';
import { getStatusLabel } from 'src/Utils/Combined';
import { IFairStallList } from 'src/Utils/type';

type IProps = {
  allAdmin: IFairStallList;
  setReload: Function;
};

const FairStallTableRow = ({ allAdmin, setReload }: IProps) => {
  // const theme = useTheme();
  // const { user } = useAuthContext();
  // const Toast = Toaster();
  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleDelete = async (itemId: number) => {
  //   if (allAdmin.fair_stall_id && user.user_admin_id) {
  //     try {
  //       const { data } = await axios.delete(
  //         `${url}/api/fair/stall/delete/${allAdmin.fair_stall_id}`
  //       );
  //       ({ data });
  //       if (data.success) {
  //         Toast.fire({
  //           icon: "success",
  //           title: "Fair stall successfully deleted",
  //         });
  //         setReload((prev: Boolean) => !prev);
  //         setOpen(false);
  //       }
  //     } catch (err) {
  //       Toast.fire({
  //         icon: "error",
  //         title: `${err.response?.data?.message}`,
  //       });
  //     }
  //   }
  // };

  return (
    <TableRow hover key={allAdmin.fair_stall_id}>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.fair_stall_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.fair_stall_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.fair_stall_type}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.fair_stall_hall_no}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.fair_stall_hall_name}
        </Typography>
      </TableCell>
      <TableCell>
        {getStatusLabel(allAdmin.fair_stall_available_status)}
      </TableCell>
      {/* <TableCell>
        <Tooltip title="view" arrow>
          <IconButton
            sx={{
              "&:hover": {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <IconButton
            onClick={handleClickOpen}
            sx={{
              "&:hover": { background: theme.colors.error.lighter },
              color: theme.palette.error.main,
            }}
            color="inherit"
            size="small"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <DeleteConfirmation
          open={open}
          handleClose={handleClose}
          text="Are you want delete this fair stall?"
          handleDeleteFun={handleDelete}
        />
      </TableCell> */}
    </TableRow>
  );
};

export default FairStallTableRow;

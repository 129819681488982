import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function HeroSectionOfMember({
  decorative = "All of information",
  title = "A Single member information",
  subtitle = "Here is the member information & Organization information, Particular of the owner / owner's information, Information organization trade, Business license information, Other association membership information and Other information",
  memberId,
}: {
  decorative?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  memberId: number | string;
}) {
  return (
    <Box
      sx={{
        flex: 1,
        height: "30vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
        my: 6,
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          color: "primary.500",
          fontWeight: 600,
          fontSize: "sm",
          textTransform: "uppercase",
          letterSpacing: 0.5,
        }}
      >
        {decorative}
      </Box>
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "4xl", sm: "5xl", md: "6xl" },
          fontWeight: 800,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: "lg",
          color: "gray.500",
          maxWidth: "54ch",
        }}
      >
        {subtitle}
      </Typography>
      <Button variant="outlined">
        <Link to={`/member-list/${memberId}/invoice`}>Get member invoice</Link>
      </Button>
    </Box>
  );
}

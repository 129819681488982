import { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { url } from 'src/Helpers/utils/Constant';
import { SingleExhibitorInvoiceInfo } from 'src/Utils/type';
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { getStatusLabel } from 'src/Utils/Combined';
import EditIcon from '@mui/icons-material/Edit';
import B2BInvoiceUpdateForm from './B2BInvoiceUpdateForm';

const B2BSingleInvoice = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [singleExhibitorInvoiceInfo, setSingleExhibitorInvoiceInfo] =
    useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);
  const { b2b_invoice_id } = useParams();

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(`${url}/api/fair/admin/invoice/b2b/${b2b_invoice_id}`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setSingleExhibitorInvoiceInfo(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [reload, b2b_invoice_id]);

  return (
    <div>
      <Helmet>
        <title>BTTF - Single B2B Invoice information</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {!loading ? (
            <Container>
              <Box sx={{ py: 2 }}>
                <Button onClick={() => navigate(-1)}>Go back</Button>
              </Box>
              <Card>
                <Box textAlign='end' sx={{ p: 2 }}>
                  <Tooltip title='Edit'>
                    <IconButton onClick={handleOpen} sx={{ cursor: 'pointer' }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <CardContent>
                  <Box key={singleExhibitorInvoiceInfo.fair_invoice_id}>
                    <Typography
                      textAlign='center'
                      gutterBottom
                      variant='h2'
                      component='div'
                    >
                      Single B2B Invoice information
                    </Typography>
                    <Typography
                      textAlign='center'
                      variant='body2'
                      color='text.secondary'
                    >
                      {singleExhibitorInvoiceInfo?.b2b_first_name}{' '}
                      {singleExhibitorInvoiceInfo?.b2b_last_name}
                    </Typography>
                    <Box sx={{ m: 3 }}>
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            B2B ID:
                          </Typography>
                          {singleExhibitorInvoiceInfo?.b2b_id}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Name:
                          </Typography>
                          {singleExhibitorInvoiceInfo?.b2b_first_name}{' '}
                          {singleExhibitorInvoiceInfo?.b2b_last_name}{' '}
                        </Grid>

                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Invoice ID:
                          </Typography>
                          {singleExhibitorInvoiceInfo?.b2b_invoice_id}
                        </Grid>

                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Invoice status:
                          </Typography>
                          {getStatusLabel(
                            singleExhibitorInvoiceInfo?.b2b_status
                          )}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Total amount:
                          </Typography>
                          {singleExhibitorInvoiceInfo?.b2b_invoice_amount}
                        </Grid>
                      </Grid>
                    </Box>
                    <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby='responsive-dialog-title'
                    >
                      <DialogTitle sx={{ p: 4 }} id='responsive-dialog-title'>
                        <Typography variant='h4'>Update b2b invoice</Typography>
                      </DialogTitle>
                      <DialogContent>
                        <B2BInvoiceUpdateForm
                          details={singleExhibitorInvoiceInfo}
                          setReload={setReload}
                          handleClose={handleClose}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </CardContent>
              </Card>
            </Container>
          ) : (
            <GlobalLoader />
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default B2BSingleInvoice;

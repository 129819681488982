import {
   IconButton,
   TableCell,
   TableRow,
   Tooltip,
   Typography,
   useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Link } from "react-router-dom";
import { IEntryFairList } from "src/Utils/type";

type IProps = {
   entryFair: IEntryFairList;
};

const EntryFairTableRow = ({ entryFair }: IProps) => {
   const theme = useTheme();
   return (
      <TableRow hover>
         <TableCell>
            <Typography variant="body1" color="text.primary" gutterBottom noWrap>
               {entryFair.fair_entry_id}
            </Typography>
         </TableCell>
         <TableCell>

            <Typography variant="body1" color="text.primary" gutterBottom noWrap>
               {moment(
                  entryFair.entry_time,
                  'HH:mm:ss')
                  .format('hh:mm A')}
            </Typography>
         </TableCell>
         <TableCell>
            <Typography variant="body1" color="text.primary" gutterBottom noWrap>
               {entryFair.visitor_first_name}
            </Typography>
         </TableCell>
         <TableCell>
            <Tooltip title="view" arrow>
               <Link to={`/visitor/list/${entryFair.visitor_id.toString()}`}>
                  <IconButton
                     sx={{
                        "&:hover": {
                           background: theme.colors.primary.lighter,
                        },
                        color: theme.palette.primary.main,
                     }}
                     color="inherit"
                     size="small"
                  >
                     <VisibilityIcon fontSize="small" />
                  </IconButton>
               </Link>
            </Tooltip>
         </TableCell>
      </TableRow>
   );
};

export default EntryFairTableRow;

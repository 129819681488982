import React, { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Chip,
  Autocomplete,
} from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { LoadingButton } from '@mui/lab';

interface IProps {
  b2b_id: number;
}

const CreateB2BInvoice = () => {
  const [userMember, setUserMember] = useState<IProps[]>([]);
  const [isFetchLoading, setFetchLoading] = useState<Boolean>(false);
  const [isMgsSent, setIsMsgSent] = useState<boolean>(false);
  const [membersId, setMembersId] = useState<IProps | null>(null);
  const [totalAmount, setTotalAmount] = useState<string>('');
  const Toast = Toaster();
  useEffect(() => {
    setFetchLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(`${url}/api/fair/admin/b2b`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setUserMember(data.data.data);
        setFetchLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setFetchLoading(false);
        } else {
          // handle error
          setFetchLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, []);
  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsMsgSent(true);
    if (!membersId) {
      Toast.fire({
        icon: 'error',
        title: `Select a member`,
      });
    } else {
      const body = {
        b2b_id: membersId.b2b_id,
        b2b_invoice_amount: totalAmount,
      };
      try {
        const { data } = await axios.post(
          `${url}/api/fair/admin/invoice/b2b`,
          body
        );
        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Invoice Successfully created`,
          });
          setIsMsgSent(false);
          setTotalAmount('');
          setMembersId(null);
        }
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: `Something went to wrong`,
        });
        setIsMsgSent(false);
      }
    }
  };

  return (
    <div>
      {!isFetchLoading ? (
        <>
          <Helmet>
            <title>BTTF - Create a b2b invoice</title>
          </Helmet>

          <Container sx={{ mt: 4 }} maxWidth='md'>
            <Card>
              <CardHeader title='Create a b2b invoice' />
              <Divider />
              <CardContent>
                <Box component='form' onSubmit={handleSubmit} sx={{ p: 2 }}>
                  <Autocomplete
                    sx={{ padding: '12px' }}
                    placeholder='Select a member'
                    value={membersId}
                    onChange={(event: any, newValue: any) => {
                      setMembersId(newValue);
                    }}
                    id='controllable-states-demo'
                    options={userMember}
                    getOptionLabel={(option) => option.b2b_first_name}
                    renderTags={(value: readonly any[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          key={index}
                          variant='filled'
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} label='Select B2B Member' />
                    )}
                  />
                  <Grid sx={{ padding: '12px' }} item xs={12} md={6} lg={6}>
                    <TextField
                      margin='normal'
                      fullWidth
                      label='Total amount'
                      required
                      id='outlined-required'
                      value={totalAmount}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setTotalAmount(e.target.value)
                      }
                      type='number'
                    />
                  </Grid>
                  {!isMgsSent ? (
                    <Button
                      type='submit'
                      variant='contained'
                      fullWidth
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                        ml: 2,
                      }}
                    >
                      Create Invoice
                    </Button>
                  ) : (
                    <LoadingButton
                      loading
                      loadingIndicator='Creating...'
                      fullWidth
                      variant='contained'
                      disabled
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                      }}
                    >
                      <span>Creating...</span>
                    </LoadingButton>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Container>
        </>
      ) : (
        <GlobalLoader />
      )}
    </div>
  );
};

export default CreateB2BInvoice;

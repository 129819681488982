import React, { useEffect, useState } from "react";
import RecentOrdersTable from "../Transactions/RecentOrdersTable";
import { Grid, Container } from "@mui/material";
import { subDays } from "date-fns";
import { CryptoOrder } from "src/models/crypto_order";
import { Card } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { adminStatus, Iadmin } from "src/Types/module/admin/adminTypes";
import AdminTable from "./AdminTable";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

const AdminShow = () => {
  const [allAdmins, setAllAdmins] = useState<Iadmin[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>("all");
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/all/status/${status}`)
          .then(function (res) {
            if (res.data.success) {
              setAllAdmins(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllAdmins([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status]);

  return (
    <Card>
      <AdminTable
        allAdmin={allAdmins}
        setStatus={setStatus}
        status={status}
        loading={loading}
      />
    </Card>
  );
};

export default AdminShow;

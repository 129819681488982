import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { getStatusLabelInvoiceStatus } from 'src/Utils/Combined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { Link } from 'react-router-dom';

type IProps = {
  allAdmin: any;
};

const B2BInvoiceTableRow = ({ allAdmin }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin?.b2b_invoice_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin?.b2b_first_name} {allAdmin?.b2b_last_name}
          </Typography>
        </TableCell>
        <TableCell>
          {allAdmin.b2b_invoice_created_at ? (
            <Typography
              variant='body1'
              color='text.primary'
              gutterBottom
              noWrap
            >
              {moment(allAdmin?.b2b_invoice_created_at).format('LL')}
            </Typography>
          ) : (
            <Typography
              variant='body1'
              color='text.primary'
              gutterBottom
              noWrap
            >
              None
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin?.b2b_invoice_amount}
          </Typography>
        </TableCell>

        <TableCell>
          {getStatusLabelInvoiceStatus(allAdmin?.b2b_status)}
        </TableCell>

        <TableCell>
          <Tooltip title='view' arrow>
            <Link to={`/b2b/b2b-invoice/${allAdmin?.b2b_invoice_id}`}>
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color='inherit'
                size='small'
              >
                <VisibilityIcon fontSize='small' />
              </IconButton>
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default B2BInvoiceTableRow;

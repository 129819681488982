import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import Spinner from 'src/components/Spinner/Spinner';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { Iadmin, IadminData } from 'src/Types/module/admin/adminTypes';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import SingleDetailsinfo from './SingleDetailsinfo';

const SingleAdmin = () => {
  const { id } = useParams();
  const Toast = Toaster();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [singleAdmin, setSingleAdmin] = useState<Iadmin | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [adminData, setAdminData] = useState<IadminData>({
    zone: 0,
    role: '',
    status: '',
  });

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/atab/admin/get/single/admin/${id}`
        );
        if (data.success) {
          setLoading(false);
          setSingleAdmin(data.data);

          setAdminData({
            ...adminData,
            zone: data.data.user_admin_zone,
            role: data.data.user_admin_role,
            status: data.data.user_admin_status,
          });
        }
      } catch (error) {
        error?.response?.data?.message;
        setLoading(false);
      }
    })();
  }, [id]);

  if (loading) {
    <Spinner />;
  }

  const { user_admin_id } = singleAdmin || {};

  const handleUpdate = async () => {
    const body = {
      user_admin_id: user_admin_id,
      user_admin_role: adminData.role,
      user_admin_status: adminData.status,
      user_admin_zone: adminData.zone,
    };

    try {
      const { data } = await axios.put(
        `${url}/api/atab/admin/update/an/admin`,
        body
      );
      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: data.message,
        });
        setShowModal(false);

        ({ adminData });

        let zoneName = '';

        if (adminData.zone === 1) {
          zoneName = 'Dhaka';
        } else if (adminData.zone === 2) {
          zoneName = 'Chittagong';
        } else {
          zoneName = 'Shylhet';
        }

        setSingleAdmin({
          ...singleAdmin,
          user_admin_zone_name: zoneName,
          user_admin_role: adminData.role,
          user_admin_status: adminData.status,
        });
      }
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: `Update Failed`,
      });
      setShowModal(false);
    }
  };

  const roleList = [
    {
      id: 1,
      role: 'Admin',
    },
    {
      id: 2,
      role: 'Super Admin',
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Toab - Single Admin</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth='lg'>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant='h4' color='text.primary'>
                Admin information
              </Typography>
              <Button onClick={() => setShowModal(!showModal)} variant='text'>
                Edit
              </Button>
            </Box>
            <SingleDetailsinfo singleAdmin={singleAdmin} />

            {/* modals wrapper */}

            <ModalsWrapper
              showModal={showModal}
              setShowModal={setShowModal}
              modalData={{ title: 'Member Info Edit' }}
            >
              <Box>
                <FormControl
                  required
                  sx={{ minWidth: '150px' }}
                  margin='normal'
                >
                  <InputLabel id='role'>Role</InputLabel>
                  <Select
                    labelId='role'
                    id='role'
                    value={adminData.role}
                    label={user_admin_id}
                    onChange={(e) => {
                      setAdminData({ ...adminData, role: e.target.value });
                    }}
                  >
                    {roleList.map((lab) => (
                      <MenuItem key={lab.id} value={lab.role}>
                        {lab.role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  required
                  margin='normal'
                  sx={{ minWidth: '150px', marginX: 4 }}
                >
                  <InputLabel required id='demo-simple-select-label'>
                    Status
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={adminData.status}
                    label='Status'
                    required
                    onChange={(e: any) => {
                      setAdminData({ ...adminData, status: e.target.value });
                    }}
                  >
                    <MenuItem value='pending'>Pending</MenuItem>
                    <MenuItem value='active'>Active</MenuItem>
                    <MenuItem value='rejected'>Rejected</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  required
                  margin='normal'
                  sx={{ minWidth: '150px' }}
                >
                  <InputLabel required id='demo-simple-select-label'>
                    Zone
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={adminData.zone}
                    label='zone'
                    required
                    onChange={(e: any) => {
                      setAdminData({ ...adminData, zone: e.target.value });
                    }}
                  >
                    <MenuItem value={1}>Dhaka</MenuItem>
                    <MenuItem value={2}>Chittagong</MenuItem>
                    <MenuItem value={3}>Sylhet</MenuItem>
                  </Select>
                </FormControl>
                <Box mt={3} textAlign='center'>
                  <Button onClick={() => handleUpdate()} variant='contained'>
                    Submit
                  </Button>
                </Box>
              </Box>
            </ModalsWrapper>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default SingleAdmin;

import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { Iadmin } from 'src/Types/module/admin/adminTypes';

const SingleDetailsinfo = ({ singleAdmin }: { singleAdmin: Iadmin }) => {
  const {
    user_admin_id,
    user_admin_email,
    user_admin_photo,
    user_admin_role,
    user_admin_status,
    user_admin_phone,
    user_admin_zone,
    user_admin_name,
    user_admin_zone_name,
  } = singleAdmin || {};
  return (
    <div>
      <Box sx={{ mt: 3 }} className='billing-info'>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom>
              ID:
            </Typography>
            {user_admin_id}
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom>
              Name:
            </Typography>
            {user_admin_name}
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom>
              Role:
            </Typography>
            {user_admin_role}
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom>
              Phone:
            </Typography>
            {user_admin_phone}
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom>
              Email:
            </Typography>
            {user_admin_email}
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom>
              Status:
            </Typography>
            {user_admin_status}
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom>
              Zone:
            </Typography>
            <Button>{user_admin_zone_name}</Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SingleDetailsinfo;
{
}

import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  CardContent,
  Divider,
  MenuItem,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { useForm, SubmitHandler, useFormState } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { SingleExhibitorInvoiceInfo } from 'src/Utils/type';
import moment from 'moment';

interface IProps {
  details: any;
  setReload: Dispatch<SetStateAction<boolean>>;
  handleClose: Function;
}

interface FormInputs {
  b2b_status: string;
  b2b_invoice_amount: number;
}

const B2BInvoiceUpdateForm = ({ details, handleClose, setReload }: IProps) => {
  const { register, handleSubmit, reset, setValue, control } =
    useForm<FormInputs>();
  const { user } = useAuthContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');

  const { isSubmitting } = useFormState({ control });

  const Toast = Toaster();

  useEffect(() => {
    if (details.b2b_status) {
      setStatus(details.b2b_status);
      setValue('b2b_status', details.b2b_status);
    }
  }, [details.b2b_status, setValue]);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (user.user_admin_id) {
      try {
        setLoading(true);
        const { data: responseData } = await axios.put(
          `${url}/api/fair/admin/invoice/b2b/${details.b2b_invoice_id}`,
          data
        );
        if (responseData.success) {
          Toast.fire({
            icon: 'success',
            title: `Update Successfully`,
          });
          setLoading(false);
          setReload((prev: boolean) => !prev);
          reset();
          handleClose();
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };

  const currencies = [
    ,
    {
      value: 'paid',
      label: 'Paid',
    },
  ];

  return (
    <>
      <Divider />
      {details && (
        <CardContent>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              mt: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  select
                  fullWidth
                  id='outlined-required'
                  label='Select status'
                  defaultValue={
                    details.b2b_status && details.b2b_status.toLowerCase()
                  }
                  {...register('b2b_status')}
                >
                  {currencies.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value.toLowerCase()}
                    >
                      {option.label.toLowerCase()}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid sx={{ padding: '12px' }} item xs={12} md={12} lg={12}>
                <TextField
                  margin='normal'
                  fullWidth
                  label='Total amount'
                  required
                  id='outlined-required'
                  type='number'
                  {...register('b2b_invoice_amount')}
                />
              </Grid>
            </Grid>
            {!isLoading ? (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                disabled={isSubmitting}
              >
                Update
              </Button>
            ) : (
              <LoadingButton
                loading
                loadingIndicator='Updating...'
                fullWidth
                variant='outlined'
                disabled
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                <span>Updating...</span>
              </LoadingButton>
            )}
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default B2BInvoiceUpdateForm;

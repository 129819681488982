import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  CardContent,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { LoadingButton } from '@mui/lab';

interface IProps {
  details: any;
  setReload: Dispatch<SetStateAction<boolean>>;
  handleClose: Function;
}

const SingleProductUpdateForm = ({
  details,
  handleClose,
  setReload,
}: IProps) => {
  const { user } = useAuthContext();
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('');
  const Toast = Toaster();

  // const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   if (details.fair_member_product_id && status && user.user_admin_id) {
  //     try {
  //       setLoading(true);
  //       const { data } = await axios.put(
  //         `${url}/api/fair/update-product/into-fair-member`,
  //         {
  //           fair_member_product_id: details.fair_member_product_id.toString(),
  //           user_fair_member_product_status: status,
  //         }
  //       );
  //       if (data.success) {
  //         Toast.fire({
  //           icon: 'success',
  //           title: `Product Status Updated Successfully`,
  //         });
  //         setLoading(false);
  //         setReload((prev: Boolean) => !prev);
  //         handleClose();
  //       } else {
  //         setLoading(false);
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //       Toast.fire({
  //         icon: 'error',
  //         title: `${err.response?.data?.message}`,
  //       });
  //     }
  //   }
  // };
  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (details.fair_member_product_id && status && user.user_admin_id) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append(
          'fair_member_product_id',
          details.fair_member_product_id.toString()
        );
        formData.append('user_fair_member_product_status', status);

        const { data } = await axios.put(
          `${url}/api/fair/update-product/into-fair-member`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Product Status Updated Successfully`,
          });

          setLoading(false);

          // Manually trigger a reload or refetch of data
          setReload((prev: boolean) => !prev);

          handleClose();
        } else {
          setLoading(false);
          console.error('PUT Request was not successful:', data);
        }
      } catch (err) {
        setLoading(false);
        console.error('Error during PUT request:', err);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message || 'An error occurred.'}`,
        });
      }
    }
  };

  return (
    <>
      <Divider />
      {details && (
        <CardContent>
          <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{
              mt: 1,
            }}
          >
            <Grid item xs={12}>
              <FormControl sx={{ mt: 2 }} required fullWidth>
                <InputLabel required id='demo-simple-select-label'>
                  Product Status
                </InputLabel>
                <Select
                  id='outlined-required'
                  defaultValue={
                    details.user_fair_member_product_status &&
                    details?.user_fair_member_product_status.toLowerCase()
                  }
                  label='                  Product Status
                  '
                  required
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value='approved'>Approved</MenuItem>
                  <MenuItem value='pending'>Pending</MenuItem>
                  <MenuItem value='rejected'>rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!isLoading ? (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update status
              </Button>
            ) : (
              <LoadingButton
                loading
                fullWidth
                loadingIndicator='Updating...'
                variant='outlined'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Updating...
              </LoadingButton>
            )}
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default SingleProductUpdateForm;

import { ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import TraineeTableRow from "./TraineeTableRow";
import { ItraineeList } from "src/Types/module/courses/trainee";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";

const applyPagination = (
  allMembers: ItraineeList[],
  page: number,
  limit: number
): ItraineeList[] => {
  return allMembers.slice(page * limit, page * limit + limit);
};

const TraineeTable = () => {
  const [allMembers, setAllMembers] = useState<ItraineeList[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<string>("all");

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        let uri: any;
        uri = `${url}/api/training/trainee/get/all/or/status/${status}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllMembers(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [status]);

  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "active",
      name: "Active",
    },
    {
      id: "pending",
      name: "Pending",
    },
    {
      id: "rejected",
      name: "Rejected",
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedMembers = applyPagination(allMembers, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box>
            <FormControl sx={{ width: "150px" }} variant="filled">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                autoWidth
              >
                {statusOptions &&
                  statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        }
        title="Trainee Member List"
      />
      <Divider />
      <TableContainer>
        <Table sx={{ position: "relative", minHeight: "250px" }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {isLoading ? (
            <TableLoader />
          ) : paginatedMembers.length ? (
            <TableBody>
              {paginatedMembers.map((allMembers: ItraineeList) => {
                return (
                  <TraineeTableRow
                    key={allMembers.user_training_trainee_id}
                    allMembers={allMembers}
                  />
                );
              })}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={allMembers?.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default TraineeTable;

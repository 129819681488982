import { useContext } from 'react';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ListSubheader,
  List,
  Button,
  ListItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/components/Context/SidebarContext';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { menuWrapper, submenuWrapper } from 'src/Utils/DashboardUtils';

const MenuWrapper = menuWrapper();

const SubMenuWrapper = submenuWrapper();

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { user } = useAuthContext();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <MenuWrapper>
        <List component='div'>
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/dashboard'
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Dashboard
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        {user.user_admin_role === 'Super Admin' && (
          <List
            component='div'
            subheader={
              <ListSubheader component='div' disableSticky>
                ADMIN
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component='div'>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to='/create-admin'
                    startIcon={<TableChartTwoToneIcon />}
                  >
                    Create Admin
                  </Button>
                </ListItem>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to='/admin-list'
                    startIcon={<TableChartTwoToneIcon />}
                  >
                    All Admin
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* Member */}
        {/* <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              Member
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List className='accordion-submodule' component='div'>
              <Accordion
                className='accordion'
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Member List
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/member-list'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      All Member
                    </Button>
                  </ListItem>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className='accordion'
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel2bh-content'
                  id='panel2bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Member Invoice
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <ListItem component='div'>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/invoices/transactions'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      All Invoice
                    </Button>
                  </ListItem>
                  <ListItem component='div'>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/invoices/create-invoice'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Create Invoice
                    </Button>
                  </ListItem>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className='accordion'
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel2bh-content'
                  id='panel2bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Paymentable Item
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <List component='div'>
                    <ListItem component='div'>
                      <Button
                        sx={{ paddingLeft: '18px!important' }}
                        disableRipple
                        component={RouterLink}
                        className='submodule-name'
                        onClick={closeSidebar}
                        to='/paymentable-item/list'
                      >
                        Paymentable Item
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        className='submodule-name'
                        sx={{ paddingLeft: '18px!important' }}
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/paymentable-item/create'
                      >
                        Create paymentable item
                      </Button>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className='accordion'
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel2bh-content'
                  id='panel2bh-header'
                >
                  <Typography className='accordion-module-name' sx={{}}>
                    Others
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <ListItem component='div'>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/member-list/meeting'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Meeting
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/member-list/create-meeting'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Create Meeting
                    </Button>
                  </ListItem>
                  <ListItem component='div'>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/event'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Event
                    </Button>
                  </ListItem>
                </AccordionDetails>
              </Accordion>
            </List>
          </SubMenuWrapper>
        </List> */}
        {/* Training */}

        {/* <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              Training
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/courses/trainee'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Trainee
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}

        {/* fair module */}
        <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              Fair
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List className='accordion-submodule' component='div'>
              <Accordion
                className='accordion'
                expanded={expanded === 'panel9'}
                onChange={handleChange('panel9')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel2bh-content'
                  id='panel2bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Exhibitor
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <List component='div'>
                    <ListItem component='div'>
                      <Button
                        sx={{ paddingLeft: '18px!important' }}
                        disableRipple
                        component={RouterLink}
                        className='submodule-name'
                        onClick={closeSidebar}
                        to='/exhibitor/list'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Exhibitor list
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        sx={{ paddingLeft: '18px!important' }}
                        disableRipple
                        component={RouterLink}
                        className='submodule-name'
                        onClick={closeSidebar}
                        to='/exhibitor/invoice-list'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Exhibitor invoice list
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        sx={{ paddingLeft: '18px!important' }}
                        disableRipple
                        component={RouterLink}
                        className='submodule-name'
                        onClick={closeSidebar}
                        to='/exhibitor/create-invoice'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Create exhibitor invoice
                      </Button>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='accordion'
                expanded={expanded === 'panel8'}
                onChange={handleChange('panel8')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel2bh-content'
                  id='panel2bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Fair
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <List component='div'>
                    <ListItem component='div'>
                      <Button
                        sx={{ paddingLeft: '18px!important' }}
                        disableRipple
                        component={RouterLink}
                        className='submodule-name'
                        onClick={closeSidebar}
                        to='/fair/list'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Fair list
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        sx={{ paddingLeft: '18px!important' }}
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/fair/stall-list'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Stall list
                      </Button>
                    </ListItem>
                    <ListItem component='div'>
                      <Button
                        sx={{ paddingLeft: '18px!important' }}
                        disableRipple
                        component={RouterLink}
                        className='submodule-name'
                        onClick={closeSidebar}
                        to='/fair/stall-application'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Stall application
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        sx={{ paddingLeft: '18px!important' }}
                        className='submodule-name'
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to='/fair/create-stall'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Create Stall
                      </Button>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion
                className='accordion'
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Visitor
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/visitor/list'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      All visitor list
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/visitor/visitor-invoice'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      All visitor invoice
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/visitor/create-invoice'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Create visitor invoice
                    </Button>
                  </ListItem>
                </AccordionDetails>
              </Accordion> */}

              <Accordion
                className='accordion'
                expanded={expanded === 'panel16'}
                onChange={handleChange('panel16')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Buyer/Seller B2B
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/b2b/list'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      All Buyer/Seller B2B list
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/b2b/b2b-invoice'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      All Buyer/Seller B2B invoice list
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/b2b/create-b2b-invoice'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Create Invoice{' '}
                    </Button>
                  </ListItem>
                  {/* <ListItem>
                    <Button
                      className="submodule-name"
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/visitor/create-invoice"
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Create visitor invoice
                    </Button>
                  </ListItem> */}
                </AccordionDetails>
              </Accordion>

              {/* event  */}
              <Accordion
                className='accordion'
                expanded={expanded === 'panel10'}
                onChange={handleChange('panel10')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Event
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/fair-event/create-event'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Create event
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/fair-event/all-event-list'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      All Event
                    </Button>
                  </ListItem>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='accordion'
                expanded={expanded === 'panel11'}
                onChange={handleChange('panel11')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Entry fair
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <ListItem>
                    <Button
                      className='submodule-name'
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/entry-fair/list'
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Entry fair list
                    </Button>
                  </ListItem>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='accordion'
                expanded={expanded === 'panel27'}
                onChange={handleChange('panel27')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='expand-icon' />}
                  aria-controls='panel2bh-content'
                  id='panel2bh-header'
                >
                  <Typography className='accordion-module-name'>
                    Guest & Speaker
                  </Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ padding: 0 }}>
                  <List component='div'>
                    <ListItem component='div'>
                      <Button
                        sx={{ paddingLeft: '18px!important' }}
                        disableRipple
                        component={RouterLink}
                        className='submodule-name'
                        onClick={closeSidebar}
                        to='/guest-speaker/guest-list'
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Guest & Speaker List
                      </Button>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </List>
          </SubMenuWrapper>
        </List>

        {/* supprot module */}
        {/* <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              SUPPORT
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/support/conversations'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Conversations
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}
        {/* <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              SMS PANEL
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/sms'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  SMS
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}

        <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              Profile
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/profile'
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  User Profile
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;

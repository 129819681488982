import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function CreateMeeting() {
  const { register, handleSubmit, reset } = useForm();
  const Toast = Toaster();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthContext();
  const currentDate = new Date();
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs(currentDate));
  const [timeValue, setTimeValue] = useState<Dayjs | null>(dayjs(currentDate));

  const handleChange = (newValue: Dayjs | null) => {
    setDateValue(newValue);
  };
  const handleTimeChange = (newValue: Dayjs | null) => {
    setTimeValue(newValue);
  };
  const onSubmit = async (inputs: any) => {
    setIsLoading(true);
    if (timeValue && dateValue) {
      const bodyFormData = new FormData();
      for (const key in inputs) {
        bodyFormData.append(key, inputs[key]);
      }
      bodyFormData.append(
        'member_meeting_time',
        timeValue.toISOString().slice(11, 19)
      );
      bodyFormData.append(
        'member_meeting_date',
        dateValue.toISOString().split('T')[0]
      );
      bodyFormData.append(
        'member_meeting_created_by',
        user.user_admin_id.toString()
      );

      // for (const [key, values] of bodyFormData.entries()) {
      //    (key, ": " + values)
      // }

      try {
        const { data } = await axios.post(
          `${url}/api/member/meeting/create/for-admin`,
          bodyFormData
        );
        ({ data });
        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Create Successfully`,
          });
          setIsLoading(false);
          reset();
        }
      } catch (err) {
        err.response.data;
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>BTTF - Create meeting</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth='lg'>
        <Card>
          <CardHeader title='Create a meeting' />
          <Divider />
          <CardContent sx={{ p: 5 }}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                '& .MuiTextField-root': { m: 2, width: '25ch' },
              }}
              noValidate
              autoComplete='off'
            >
              <TextField
                label='Member meeting title:'
                id='filled-size-small'
                variant='filled'
                size='medium'
                required
                type='text'
                {...register('member_meeting_title')}
              />
              <TextField
                label='Member meeting detail:'
                id='filled-size-small'
                variant='filled'
                size='medium'
                required
                type='text'
                {...register('member_meeting_detail')}
              />
              <TextField
                label='Member meeting place:'
                id='filled-size-small'
                variant='filled'
                required
                size='medium'
                type='text'
                {...register('member_meeting_place')}
              />
              <TextField
                label='Member meeting remark:'
                id='filled-size-small'
                variant='filled'
                size='medium'
                type='text'
                {...register('member_meeting_remark')}
              />
              <TextField
                id='filled-size-small'
                variant='filled'
                size='medium'
                type='file'
                label='Upload a meeting image'
                InputLabelProps={{
                  shrink: true,
                }}
                {...register('member_meeting_img')}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label='Date desktop'
                  inputFormat='MM/DD/YYYY'
                  value={dateValue}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TimePicker
                  label='Time'
                  value={timeValue}
                  onChange={handleTimeChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}
              <Grid>
                {!isLoading ? (
                  <Button
                    type='submit'
                    variant='contained'
                    disableElevation
                    sx={{
                      margin: 2,
                    }}
                  >
                    Create Meeting
                  </Button>
                ) : (
                  <LoadingButton
                    loading
                    loadingIndicator='Creating...'
                    variant='contained'
                    disabled
                    sx={{
                      mt: 3,
                      mb: 2,
                      py: 1,
                    }}
                  >
                    <span>Creating...</span>
                  </LoadingButton>
                )}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default CreateMeeting;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import { muiStyles } from '../../../Utils/Styles';
import Toaster from '../../../Toaster/Toaster';
import { url } from '../../../Helpers/utils/Constant';
import { Helmet } from 'react-helmet-async';

const useStyles = muiStyles;
const CreateAdmin = () => {
  const classes = useStyles();
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [zone, setZone] = React.useState<string>('');
  const [role, setRole] = React.useState<string>('');

  const handleChange = (event: SelectChangeEvent) => {
    setZone(event.target.value as string);
  };

  const onSubmit = async (inputs: any) => {
    try {
      const { user_admin_confirm_password, user_admin_photo, ...body } = inputs;

      const bodyFormData = new FormData();

      for (const key in body) {
        bodyFormData.append(key, body[key]);
      }

      bodyFormData.append('user_admin_photo', user_admin_photo[0]);
      bodyFormData.append('user_admin_zone', zone);
      bodyFormData.append('role', role);

      const { data } = await axios.post(
        `${url}/api/atab/admin/create/an/admin`,
        bodyFormData
      );

      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: `Admin Created Successfully`,
        });

        reset();
      }
    } catch (err: any) {
      Toast.fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>Toab - Create Admin</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth='lg'>
        <Card>
          <CardHeader title='Create Admin' />
          <Divider />
          <CardContent>
            <Box sx={{ p: 2 }} className=''>
              <Box
                component='form'
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  mt: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='name'
                      label='User Admin Name'
                      {...register('user_admin_name')}
                      type='text'
                      autoFocus
                      variant='filled'
                      className={classes.inputText}
                      InputProps={{ classes }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='phone'
                      label='Phone'
                      {...register('user_admin_phone')}
                      type='number'
                      autoFocus
                      variant='filled'
                      className={classes.inputText}
                      InputProps={{ classes }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='email'
                      label='Email'
                      {...register('user_admin_email')}
                      type='email'
                      autoFocus
                      variant='filled'
                      className={classes.inputText}
                      InputProps={{ classes }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FormControl required margin='normal' fullWidth>
                      <InputLabel required id='demo-simple-select-label'>
                        Role
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={role}
                        label='role'
                        required
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                      >
                        <MenuItem value='Super-Admin'>Super Admin</MenuItem>
                        <MenuItem value='Admin'>Admin</MenuItem>
                        <MenuItem value='Zone-Admin'>Zone Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <FormControl required margin='normal' fullWidth>
                      <InputLabel required id='demo-simple-select-label'>
                        Zone
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={zone}
                        label='zone'
                        required
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>Dhaka</MenuItem>
                        <MenuItem value={2}>Chittagong</MenuItem>
                        <MenuItem value={3}>Sylhet</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='file'
                      label='Choose Admin Photo'
                      InputLabelProps={{ shrink: true }}
                      {...register('user_admin_photo')}
                      autoComplete='file'
                      type='file'
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <div className='form-grid'>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    {...register('user_admin_password')}
                    label='Password'
                    type={`${showPassword ? 'text' : 'password'}`}
                    id='password'
                    autoComplete='current-password'
                    variant='filled'
                    className={classes.inputText}
                    InputProps={{ classes }}
                  />
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    {...register('user_admin_confirm_password')}
                    label='Confirm Password'
                    type={`${showPassword ? 'text' : 'password'}`}
                    id='password2'
                    autoComplete='current-password'
                    variant='filled'
                    className={classes.inputText}
                    InputProps={{ classes }}
                  />
                </div>

                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={() => setShowPassword(!showPassword)}
                      value={showPassword}
                      color='primary'
                    />
                  }
                  label='Show Password'
                />

                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                  }}
                >
                  Create Admin
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default CreateAdmin;

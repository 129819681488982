import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  CardContent,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { useForm } from 'react-hook-form';
import { IFairMemberInfo } from 'src/Types/module/fair/fairSingleMemberInfo';
import { LoadingButton } from '@mui/lab';

interface IProps {
  details: IFairMemberInfo;
  setReload: Dispatch<SetStateAction<boolean>>;
  handleClose: Function;
}

const FairMemberInfoUpdateForm = ({
  details,
  handleClose,
  setReload,
}: IProps) => {
  const { register, handleSubmit, reset } = useForm();
  const { user } = useAuthContext();
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('');
  const Toast = Toaster();

  useEffect(() => {
    if (details.user_fair_member_status) {
      setStatus(details.user_fair_member_status);
    }
  }, [details.user_fair_member_status]);
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setStatus(e.target.value);
    }
  };

  details;
  const onSubmit = async (inputs: any) => {
    if (details.user_fair_member_id && status && user.user_admin_id) {
      try {
        setLoading(true);
        const {
          user_fair_member_contact_name,
          user_fair_member_contact_designation,
          user_fair_member_email,
          user_fair_member_contact_email,
          user_fair_member_contact_number,
        } = inputs;

        const bodyFormData = new FormData();

        for (const key in inputs) {
          bodyFormData.append(key, inputs[key]);
        }

        const { data } = await axios.put(
          `${url}/api/fair/member/fair-member/update`,
          {
            user_fair_member_id: details.user_fair_member_id.toString(),
            user_fair_member_status: status,
            user_fair_member_contact_name,
            user_fair_member_contact_designation,
            user_fair_member_email,
            user_fair_member_contact_email,
            user_fair_member_contact_number,
          }
        );

        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Admin Created Successfully`,
          });
          setLoading(false);
          setReload((prev: Boolean) => !prev);
          reset();
          handleClose();
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
        ({ err });
      }
    }
  };

  return (
    <>
      <Divider />
      {details && (
        <CardContent>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              mt: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  margin='normal'
                  fullWidth
                  label='Fair Member name'
                  defaultValue={details.user_fair_member_contact_name}
                  required
                  id='outlined-required'
                  {...register('user_fair_member_contact_name')}
                  type='text'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='outlined-required'
                  label='Contact number'
                  defaultValue={details.user_fair_member_contact_number}
                  {...register('user_fair_member_contact_number')}
                  type='number'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='outlined-required'
                  label='Fair member designation'
                  defaultValue={details.user_fair_member_contact_designation}
                  {...register('user_fair_member_contact_designation')}
                  type='text'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='outlined-required'
                  label='Fair member email'
                  defaultValue={details.user_fair_member_email}
                  {...register('user_fair_member_email')}
                  type='text'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='outlined-required'
                  label='Fair member contact email'
                  defaultValue={details.user_fair_member_contact_email}
                  {...register('user_fair_member_contact_email')}
                  type='text'
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ mt: 2 }} required fullWidth>
                  <InputLabel required id='demo-simple-select-label'>
                    Member status
                  </InputLabel>
                  <Select
                    id='outlined-required'
                    value={status}
                    label='Invoice status'
                    required
                    onChange={handleStatusChange}
                  >
                    <MenuItem value='approved'>approved</MenuItem>
                    <MenuItem value='pending'>pending</MenuItem>
                    <MenuItem value='rejected'>rejected</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {!isLoading ? (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update
              </Button>
            ) : (
              <LoadingButton
                loading
                loadingIndicator='Updating...'
                fullWidth
                variant='outlined'
                disabled
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                <span>Updating...</span>
              </LoadingButton>
            )}
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default FairMemberInfoUpdateForm;

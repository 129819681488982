import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { muiStyles } from 'src/Utils/Styles';
import Toaster from 'src/Toaster/Toaster';
import { url } from 'src/Helpers/utils/Constant';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { ChangeEvent } from 'react';
import FormEvent from 'react';

const useStyles = muiStyles;
const CreatePaymentableItem = () => {
  const classes = useStyles();
  const Toast = Toaster();
  const { user } = useAuthContext();

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (inputs: any) => {
    if (user.user_admin_id) {
      try {
        const {
          member_paymentable_item_name,
          member_paymentable_item_bdt_amount,
          member_paymentable_item_usd_amount,
        } = inputs;

        const { data } = await axios.post(
          `${url}/api/atab/payment/create/paymentable/item`,
          {
            member_paymentable_item_name,
            member_paymentable_item_bdt_amount,
            member_paymentable_item_usd_amount,
          }
        );

        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Admin Created Successfully`,
          });
          reset();
        }
      } catch (err: any) {
        err;
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };
  return (
    <div>
      <Helmet>
        <title>Create Paymentable Item</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth='lg'>
        <Card>
          <CardHeader title='Create Paymentable Item' />
          <Divider />

          <CardContent>
            <Box sx={{ p: 2 }} className=''>
              <Box
                component='form'
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  mt: 1,
                }}
              >
                <div className='form-grid'>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    {...register('member_paymentable_item_name')}
                    label='Paymentable item name'
                    id='member_paymentable_item_name'
                    variant='filled'
                    type='text'
                    className={classes.inputText}
                    InputProps={{ classes }}
                  />
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='name'
                      label='Paymentable item BDT amount'
                      {...register('member_paymentable_item_bdt_amount')}
                      type='number'
                      variant='filled'
                      className={classes.inputText}
                      InputProps={{ classes }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='phone'
                      label='Paymentable item USD amount'
                      {...register('member_paymentable_item_usd_amount')}
                      type='number'
                      variant='filled'
                      className={classes.inputText}
                      InputProps={{ classes }}
                    />
                  </Grid>
                </Grid>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                  }}
                >
                  Create Paymentable Item
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default CreatePaymentableItem;

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { Card, CardContent, Typography } from '@mui/material';
import { url } from 'src/Helpers/utils/Constant';
import { ICompanyInfo } from 'src/Types/module/member/memberTypes';
import { useParams } from 'react-router';
import OrganizationInfoDetails from './CompanyInfoDetailsComponents/OrganizationInfoDetails';
import ParticularsOwnerInfoDetails from './CompanyInfoDetailsComponents/ParticularsOwnerInfoDetails';
import TradeLicenseInfoDetails from './CompanyInfoDetailsComponents/TradeLicenseInfoDetails';
import OtherbusinessLicenseInfoDetails from './CompanyInfoDetailsComponents/OtherbusinessLicenseInfoDetails';
import AssociationMeberInfoDetails from './CompanyInfoDetailsComponents/AssociationMeberInfoDetails';
import OthersInfoDetails from './CompanyInfoDetailsComponents/OthersInfoDetails';
import { Helmet } from 'react-helmet-async';
import MemberInfoDetails from './CompanyInfoDetailsComponents/MemberInfoDetails';
import { ImemberInvoice } from 'src/Types/module/common/commonTypes';
import CompanyInvoices from './CompanyInfoDetailsComponents/CompanyInvoices';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import HeroSectionOfMember from './CompanyInfoDetailsComponents/HeroSectionOfMember';

const CompanyInfoDetails = () => {
  const { memberId } = useParams();
  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>();
  const [memberInvoice, setAllMemberInvoice] = useState<ImemberInvoice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/atab/user-member/get/a/member/for-admin/${memberId}`
        );
        if (data.success) {
          setLoading(false);
          setCompanyInfo(data.data);
        }
      } catch (error) {
        error?.response?.data?.message;
        setLoading(false);
      }
    })();
  }, [memberId]);

  // useEffect(() => {
  //   setLoading(true);
  //   (async function () {
  //     try {
  //       const { data } = await axios.get(
  //         `${url}/api/atab/invoice/get/all/by/user-member/status/${memberId}/all`
  //       );
  //       if (data.success) {
  //         ({ data }, "from invoice");
  //         setLoading(false);
  //         setAllMemberInvoice(data.data);
  //       }
  //     } catch (error) {
  //       (error?.response?.data?.message);
  //       setLoading(false);
  //     }
  //   })();
  // }, [memberId]);

  return !loading ? (
    <>
      <Helmet>
        <title>BTTF - Company info</title>
      </Helmet>
      <Box>
        <Box>
          <Card>
            <CardContent>
              {!companyInfo ? (
                <Typography
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '85vh',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  variant='h2'
                >
                  Company information yet not exist
                </Typography>
              ) : (
                <Box>
                  <HeroSectionOfMember memberId={memberId} />
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <MemberInfoDetails memberInfo={companyInfo} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <OrganizationInfoDetails companyInfo={companyInfo} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <ParticularsOwnerInfoDetails
                          companyInfo={companyInfo}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TradeLicenseInfoDetails companyInfo={companyInfo} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <OtherbusinessLicenseInfoDetails
                          companyInfo={companyInfo}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <AssociationMeberInfoDetails
                          companyInfo={companyInfo}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <OthersInfoDetails companyInfo={companyInfo} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  ) : (
    <GlobalLoader />
  );
};

export default CompanyInfoDetails;

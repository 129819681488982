import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Chip,
  Autocomplete,
  Grid,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import Toaster from '../../../../Toaster/Toaster';
import { url } from '../../../../Helpers/utils/Constant';
import { Helmet } from 'react-helmet-async';
import { muiStyles } from '../../../../Utils/Styles';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { ISpeaker } from 'src/Types/module/FairEvent/FairEventType';
import moment from 'moment';

const useStyles = muiStyles;
const CreateEvent = () => {
  const { user } = useAuthContext();
  const classes = useStyles();
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [allSpeakers, setAllSpeakers] = useState<any>([]);
  const [speakerId, setSpeakerId] = useState<string[]>([]);
  const currentDate = new Date();
  const [timeValue, setTimeValue] = useState({
    event_start_time: dayjs(currentDate),
    event_end_time: dayjs(currentDate),
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`${url}/api/fair/get/speaker/all/all`);
      if (data.success) {
        setAllSpeakers(data.data);
      }
    })();
  }, []);
  const selectedSpeaker = speakerId.map((speaker) => {
    return parseInt(speaker.split(' ')[0]);
  });

  const onSubmit = async (inputs: any) => {
    // setLoading(true);
    inputs.event_created_by = user.user_admin_id;
    inputs.event_speaker = JSON.stringify(selectedSpeaker);
    inputs.event_start_time = dayjs(timeValue.event_start_time).format(
      'HH:mm:mm'
    );

    inputs.event_end_time = dayjs(timeValue.event_end_time).format('HH:mm:mm');

    console.log(inputs);
    try {
      const bodyFormData = new FormData();
      for (const key in inputs) {
        if (typeof inputs[key] === 'object') {
          bodyFormData.append(key, inputs[key][0]);
        } else {
          bodyFormData.append(key, inputs[key]);
        }
      }
      const { data } = await axios.post(
        `${url}/api/fair/event/for-admin/create`,
        bodyFormData
      );
      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: data.message,
        });

        setTimeValue({
          event_start_time: dayjs(currentDate),
          event_end_time: dayjs(currentDate),
        });
        reset();
        setLoading(false);
      } else {
        Toast.fire({
          icon: 'error',
          title: data.message,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div>
      <Helmet>
        <title>Fair - Create Event</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth='lg'>
        <Card>
          <CardHeader title='Create Event' />
          <Divider />
          <CardContent>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                mt: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='event title'
                    label='Enter event title'
                    {...register('event_title')}
                    type='text'
                    autoFocus
                    className={classes.inputText}
                    InputProps={{ classes }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='event type'
                    label='Enter event type'
                    {...register('event_type')}
                    type='text'
                    autoFocus
                    className={classes.inputText}
                    InputProps={{ classes }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='event venue'
                    label='Enter event venue '
                    {...register('event_venu')}
                    type='text'
                    autoFocus
                    className={classes.inputText}
                    InputProps={{ classes }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='event date'
                    label='Enter event date'
                    InputLabelProps={{ shrink: true }}
                    {...register('event_date')}
                    type='date'
                    autoFocus
                    className={classes.inputText}
                    InputProps={{ classes }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Autocomplete
                    sx={{ padding: '12px' }}
                    multiple
                    id='tags-filled'
                    options={allSpeakers?.map(
                      (option: any) =>
                        option?.guest_speaker_id +
                        ' ' +
                        option?.guest_speaker_name
                    )}
                    freeSolo
                    onChange={(event: any, newValue: string[]) =>
                      setSpeakerId(newValue)
                    }
                    renderTags={(value: readonly any[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant='filled'
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label='Select speaker'
                        placeholder='More..'
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='file'
                    label='Choose event Photo'
                    InputLabelProps={{ shrink: true }}
                    {...register('event_photo')}
                    autoComplete='file'
                    type='file'
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label='Start time'
                      value={timeValue.event_start_time}
                      onChange={(value: Dayjs | null) => {
                        setTimeValue({
                          ...timeValue,
                          event_start_time: value,
                        });
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={4} mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label='End time'
                      value={timeValue.event_end_time}
                      onChange={(value: Dayjs | null) =>
                        setTimeValue({
                          ...timeValue,
                          event_end_time: value,
                        })
                      }
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    multiline
                    className={classes.inputText}
                    InputProps={{ classes }}
                    rows={4}
                    id='event description'
                    label='Enter event description'
                    {...register('event_description')}
                    type='text'
                    autoFocus
                  />
                </Grid>
              </Grid>
              {loading ? (
                <Button
                  disabled
                  fullWidth
                  variant='contained'
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                  }}
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                  }}
                >
                  Create Event
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default CreateEvent;

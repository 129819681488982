import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { getStatusLabel } from 'src/Utils/Combined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { visitorList } from 'src/Utils/type';

interface IProps {
  visitor: any;
  setReload: Function;
}

const B2BTableRow = ({ visitor, setReload }: IProps) => {
  const theme = useTheme();
  return (
    <TableRow hover key={visitor?.visitor_id}>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {visitor?.b2b_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex' }}>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {visitor?.b2b_first_name} {visitor?.b2b_last_name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {visitor?.b2b_email}
        </Typography>
      </TableCell>

      <TableCell>
        <Link to={`/b2b/list/${visitor?.b2b_id.toString()}`}>
          <Tooltip title='view' arrow>
            <IconButton
              sx={{
                '&:hover': {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color='inherit'
              size='small'
            >
              <VisibilityIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default B2BTableRow;

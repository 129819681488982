import { FC, ChangeEvent, useState } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
} from "@mui/material";

import PaymentableItemTableRow from "./PaymentableItemTableRow";
import { IPaymentableItemList } from "src/Types/module/paymentableItem/itemList";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";

interface RecentOrdersTableProps {
  className?: string;
  allInvoice: IPaymentableItemList[];
  setReload: (value: Boolean) => void;
  loading: Boolean;
}

const applyPagination = (
  allInvoice: IPaymentableItemList[],
  page: number,
  limit: number
): IPaymentableItemList[] => {
  return allInvoice.slice(page * limit, page * limit + limit);
};

const PaymentableItemTable: FC<RecentOrdersTableProps> = ({
  allInvoice,
  setReload,
  loading,
}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const paginatedInvoice = applyPagination(allInvoice, page, limit);

  return (
    <Card>
      <TableContainer>
        <Table sx={{ position: "relative", minHeight: "250px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Paymentable ID</TableCell>
              <TableCell>Paymentable item name</TableCell>
              <TableCell>Paymentable item BDT amount</TableCell>
              <TableCell>Paymentable item USD amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableLoader />
          ) : paginatedInvoice.length ? (
            <TableBody>
              {paginatedInvoice.map((allInvoice: any, index) => (
                <PaymentableItemTableRow
                  allInvoice={allInvoice}
                  setReload={setReload}
                  key={index + 1}
                />
              ))}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={allInvoice.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default PaymentableItemTable;

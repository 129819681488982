import { FC, ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
} from "@mui/material";
import SuspenseLoader from "src/components/SuspenseLoader";
import VisitorTableRow from "./B2BTableRow";
import { visitorList } from "src/Utils/type";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import B2BTableRow from "./B2BTableRow";
import B2BReportExcelGeneration from "./B2BReportExcelGeneration";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";

const applyPagination = (
  fairAllMember: visitorList[],
  page: number,
  limit: number
): visitorList[] => {
  return fairAllMember.slice(page * limit, page * limit + limit);
};

const B2BTable = ({ fairAllMember, loading, setStatus, status, setReload }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "pending",
      name: "Pending",
    },
    {
      id: "approved",
      name: "Approved",
    },
    {
      id: "rejected",
      name: "Rejected",
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(fairAllMember, page, limit);
  const getDownloadData: () => Promise<{
    total: number;
    data: any;
  }> = async () => {
    try {
      const { data } = await axios.get(
        `${url}/api/fair/admin/b2b?type=1&limit=${limit}&skip=${
          Number(limit) * Number(page)
        }`
      );

      if (data.success) {
        return data;
      } else {
        return { total: 0, data: [] };
      }
    } catch (err) {
      return {
        total: 0,
        data: [],
      };
    }
  };
  return (
    <Card>
      <CardHeader
        action={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "320px",
            }}
          >
            <B2BReportExcelGeneration
              getDownloadData={getDownloadData}
              fileName={"b2b_registartion_report_excel"}
            />{" "}
            {/* <FormControl fullWidth variant='outlined'>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label='Status'
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Box>
        }
        title="Buyer/Seller B2B list"
      />

      <Divider />
      <TableContainer>
        <Table sx={{ position: "relative", minHeight: "250px" }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              {/* <TableCell>Gender</TableCell> */}
              <TableCell>Email</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableLoader />
          ) : paginatedInvoice.length ? (
            <TableBody>
              {paginatedInvoice.map((visitor: any) => {
                return (
                  <B2BTableRow
                    key={visitor.b2b_id}
                    visitor={visitor}
                    setReload={setReload}
                  />
                );
              })}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={fairAllMember.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default B2BTable;

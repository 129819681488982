import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import InvoiceTable from "./InvoiceTable";
import { Iinvoice } from "src/Types/module/invoice/invoiceTypes";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
const InvoiceShow = () => {
  const [allPayments, setAllPayments] = useState<Iinvoice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("all");

  useEffect(() => {
    setLoading(true);
    try {
      axios
        .get(`${url}/api/atab/invoice/get/all/for-admin/status/${status}`)
        .then(function (res) {
          if (res.data.success) {
            setAllPayments(res.data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(function (err) {
          setAllPayments([]);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  }, [status]);

  const allInvoice: Iinvoice[] = allPayments;

  return (
    <>
      <Card>
        <InvoiceTable
          allInvoice={allInvoice}
          loading={loading}
          setStatus={setStatus}
          status={status}
        />
      </Card>
    </>
  );
};

export default InvoiceShow;

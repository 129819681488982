import { Label, SimCardDownload } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { IsingleMemberMeeting } from "src/Types/module/meeting/singleMemberMeeting";
import { getStatusLabel } from "src/Utils/Combined";
import MeetingItems from "./MeetingItems";
import MeetingUpdateForm from "./MeetingUpdateForm";
import Image from "mui-image";
import { imgUrl, url } from "src/Helpers/utils/Constant";

const BillingInfo = ({
  singleInvoiceDetails,
  editMode,
  handleEditModeChange,
}: {
  singleInvoiceDetails: IsingleMemberMeeting;
  editMode: boolean;
  handleEditModeChange: any;
}) => {
  singleInvoiceDetails;
  return (
    <div>
      <Box sx={{ mt: 3, p: 3 }} className="billing-info">
        {!editMode ? (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting ID:
              </Typography>
              {singleInvoiceDetails?.member_meeting_id}
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Member ID:
              </Typography>
              {singleInvoiceDetails?.meeting_created_by_id}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                {/* <TextField
          label="Representative Name:"
          id="filled-size-small"
          defaultValue={singleInvoiceDetails?.user_member_representative_name}
          variant="filled"
          size="small"
        /> */}
                Meeting Title:
              </Typography>
              {singleInvoiceDetails?.member_meeting_title}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting details:
              </Typography>
              {singleInvoiceDetails?.member_meeting_detail}
            </Grid>
            {singleInvoiceDetails.member_meeting_remark && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting Remark:
                </Typography>
                {singleInvoiceDetails?.member_meeting_remark}
              </Grid>
            )}

            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Place:
              </Typography>
              {singleInvoiceDetails?.member_meeting_place}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Creator:
              </Typography>
              {singleInvoiceDetails?.meeting_created_by_name}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Status:
              </Typography>
              <Button>
                {singleInvoiceDetails?.member_meeting_status.toString()}
              </Button>

              {/* {getStatusLabel(
              singleInvoiceDetails?.atab_payment_invoice_status.toString()
            )} */}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting Date:
              </Typography>
              {moment(singleInvoiceDetails?.member_meeting_date).format("LL")}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting time:
              </Typography>
              {moment(
                singleInvoiceDetails?.member_meeting_time,
                "HH:mm"
              ).format("hh:mm A")}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Meeting created date:
              </Typography>
              {moment(singleInvoiceDetails?.member_meeting_created_at).format(
                "LL"
              )}
            </Grid>
            {singleInvoiceDetails?.meeting_updated_by_id && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting uptated by ID:
                </Typography>
                {singleInvoiceDetails?.meeting_updated_by_id}
              </Grid>
            )}

            {singleInvoiceDetails?.meeting_updated_by_name && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting updated by:
                </Typography>
                {singleInvoiceDetails?.meeting_updated_by_name}
              </Grid>
            )}
            <Grid item xs={2} sm={4} md={4}>
              <h4>Meeting Image</h4>
              <Box sx={{ display: "flex", justifyContent: "left" }}>
                {singleInvoiceDetails.member_meeting_img && (
                  <Image
                    src={
                      singleInvoiceDetails?.member_meeting_img
                        ? `${imgUrl}/member_meeting_files/${singleInvoiceDetails?.member_meeting_img}`
                        : "/static/images/avatars/avatar2.png"
                    }
                    height="80px"
                    width="160px"
                    fit="cover"
                    duration={3000}
                    showLoading={false}
                    errorIcon={true}
                    distance="100px"
                    shiftDuration={900}
                    bgColor="inherit"
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <MeetingUpdateForm
            handleEditModeChange={handleEditModeChange}
            singleInvoiceDetails={singleInvoiceDetails}
          />
        )}

        {/* Form input  */}

        <MeetingItems singleInvoiceDetails={singleInvoiceDetails} />
      </Box>
    </div>
  );
};

export default BillingInfo;

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Grid,
  MenuItem,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { visitorSingleInvoiceInfo } from 'src/Types/module/visitorInvoice/visitorSingleInvoiceInfo';

type IProps = {
  singleInvoiceDetails: visitorSingleInvoiceInfo;
  handleEditModeChange: Function;
};

function InvoiceUpdateForm({
  singleInvoiceDetails,
  handleEditModeChange,
}: IProps) {
  const { register, handleSubmit, reset } = useForm();
  const [isSuccess, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [getPaymentMethod, setGetPaymentMethod] = useState(null);
  const { user } = useAuthContext();
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    new Date(
      singleInvoiceDetails.visitor_payment_invoice_transaction_date &&
      singleInvoiceDetails.visitor_payment_invoice_transaction_date
    )
  );
  const Toast = Toaster();
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(new Date(event.target.value));
  };
  const currencies = [
    {
      value: 'pending',
      label: 'Pending',
    },
    {
      value: 'paid',
      label: 'Paid',
    },
    {
      value: 'cancelled',
      label: 'Cancelled',
    },
    {
      value: 'reported',
      label: 'Reported',
    },
  ];

  const paymentMethod = [
    {
      value: 'Cash',
      label: 'Cash',
    },
    {
      value: 'SSL Commerz',
      label: 'SSL Commerz',
    },
    {
      value: 'Instrument',
      label: 'Instrument',
    },
    {
      value: 'Pay Order',
      label: 'Pay Order',
    },
  ];

  const onSubmit = async (inputs: any) => {
    if (singleInvoiceDetails.visitor_payment_invoice_id && user.user_admin_id) {
      try {
        setLoading(true);
        const bodyFormData = new FormData();
        for (const key in inputs) {
          bodyFormData.append(key, inputs[key]);
        }
        bodyFormData.append(
          'visitor_payment_invoice_transaction_id',
          singleInvoiceDetails.visitor_payment_invoice_transaction_id
        );
        bodyFormData.append(
          'visitor_payment_invoice_updated_by',
          user.user_admin_id.toString()
        );
        bodyFormData.append(
          'visitor_payment_invoice_payment_method',
          getPaymentMethod
        );
        bodyFormData.append('visitor_payment_invoice_invoice_file', uploadFile);
        bodyFormData.append(
          'visitor_payment_invoice_transaction_date',
          selectedDate.toISOString().slice(0, 10)
        );

        const { data } = await axios.put(
          `${url}/api/fair/visitor/for-admin/visitor-invoice-update/${singleInvoiceDetails.visitor_payment_invoice_id}`,
          bodyFormData
        );
        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Invoice Update Successfully`,
          });
          setSuccess(true);
          reset();
          setLoading(false);
        }
      } catch (err: any) {
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleEditModeChange();
    }
  }, [isSuccess]);

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '35ch' },
      }}
      noValidate
      autoComplete='off'
    >
      <Grid
        container
        sx={{ paddingLeft: '40px' }}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <TextField
          id='filled-select-currency'
          select
          size='small'
          label='Select Payment Method'
          value={
            getPaymentMethod
            // (singleInvoiceDetails?.atab_payment_invoice_payment_method &&
            //   singleInvoiceDetails?.atab_payment_invoice_payment_method.toLowerCase())
          }
          onChange={(e) => setGetPaymentMethod(e.target.value.toLowerCase())}
          variant='filled'
        >
          {paymentMethod.map((option) => (
            <MenuItem key={option.value} value={option.value.toLowerCase()}>
              {option.label.toLowerCase()}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id='filled-select-currency'
          select
          size='small'
          label='Select status'
          defaultValue={singleInvoiceDetails?.visitor_payment_invoice_status.toLowerCase()}
          {...register('visitor_payment_invoice_status')}
          variant='filled'
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value.toLowerCase()}>
              {option.label.toLowerCase()}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label='Transaction ID:'
          id='filled-size-small'
          defaultValue={
            singleInvoiceDetails?.visitor_payment_invoice_transaction_id
          }
          variant='filled'
          size='small'
          type='number'
          {...register('member_payment_invoice_transaction_id')}
        />
        <TextField
          id='filled-size-small'
          variant='filled'
          type='file'
          label='Payment Invoice File'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e: any) => setUploadFile(e.target.files[0])}
        />
        <TextField
          label='Select invoice Date'
          type='date'
          value={selectedDate && selectedDate?.toISOString().slice(0, 10)}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
        />
        {/* <Box sx={{ margin: "10px 20px 5px 10px" }}>
          <TextareaAutosize
            placeholder="Type in invoice remark"
            minRows={3}
            maxRows={10}
            required
            defaultValue={singleInvoiceDetails?.atab_payment_invoice_remark}
            id="outlined-required"
            {...register("atab_payment_invoice_remark")}
            style={{ width: "42ch" }}
          />
        </Box> */}
      </Grid>
      {!loading ? (
        <Button
          type='submit'
          variant='contained'
          disableElevation
          sx={{
            margin: 2,
          }}
        >
          Update Invoice
        </Button>
      ) : (
        <LoadingButton
          loading
          loadingIndicator='Updating...'
          variant='outlined'
        >
          Updating...
        </LoadingButton>
      )}
    </Box>
  );
}

export default InvoiceUpdateForm;

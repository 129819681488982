import React from 'react';
import { Container, Grid } from '@mui/material';
import { Card } from '@mui/material';
import EventsTable from 'src/components/FairEventComponents/EventsTable';

const AllEventList = () => {
  return (
    <div>
      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <EventsTable />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AllEventList;

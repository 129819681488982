import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { useForm } from 'react-hook-form';
import { muiStyles } from 'src/Utils/Styles';
import Toaster from 'src/Toaster/Toaster';

import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';

import moment from 'moment';
import { ISingleFairEvent } from 'src/Types/module/FairEvent/FairEventType';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

const useStyles = muiStyles;

interface IProps {
  singleEvent: ISingleFairEvent;
  handleEditModeChange: Function;
  handleClose: Function;
}
const EventInfoUpdate = ({
  handleEditModeChange,
  handleClose,
  singleEvent,
}: IProps) => {
  const { user } = useAuthContext();
  const classes = useStyles();
  const Toast = Toaster();
  const { register, handleSubmit } = useForm();
  const [status, setStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setStatus(e.target.value);
    }
  };
  const [success, setSuccess] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [startValue, setStartValue] = React.useState<Date | null>(null);
  const [endValue, setEndValue] = React.useState<Date | null>(null);
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(new Date(event.target.value));
  };
  const currentDate = new Date();
  const [timeValue, setTimeValue] = useState({
    event_start_time: dayjs(currentDate),
    event_end_time: dayjs(currentDate),
  });
  const onSubmit = async (inputs: any) => {
    setLoading(true);
    inputs.event_updated_by = user.user_admin_id;
    inputs.event_id = singleEvent?.event_id;
    inputs.event_status = status;
    // if (startValue !== null) {
    //   const startTime = moment(startValue.toISOString());
    //   inputs.event_start_time = startTime.format('HH:mm:ss');
    // }

    // if (endValue !== null) {
    //   const endTime = moment(endValue.toISOString());
    //   inputs.event_end_time = endTime.format('HH:mm:ss');
    // }
    inputs.event_start_time = dayjs(timeValue.event_start_time).format(
      'HH:mm:mm'
    );

    inputs.event_end_time = dayjs(timeValue.event_end_time).format('HH:mm:mm');

    const formData = new FormData();
    try {
      for (const key in inputs) {
        if (inputs[key]) {
          if (typeof inputs[key] === 'object') {
            if (inputs[key].length) {
              formData.append(key, inputs[key][0]);
            }
          } else {
            formData.append(key, inputs[key]);
          }
        }
      }

      const { data } = await axios.put(
        `${url}/api/fair/event/for-admin/update`,
        formData
      );
      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: data.message,
        });
        setLoading(false);
        setSuccess((prv) => !prv);
        handleClose();
      } else {
        Toast.fire({
          icon: 'error',
          title: data.message,
        });
        setLoading(false);
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: `${error.response?.data?.message}`,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (success) {
      handleEditModeChange();
    }
  }, [success]);
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            margin='normal'
            fullWidth
            id='event title'
            defaultValue={singleEvent?.event_title}
            label='Enter event title'
            {...register('event_title')}
            type='text'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            margin='normal'
            fullWidth
            id='event type'
            label='Enter event type'
            defaultValue={singleEvent?.event_type}
            {...register('event_type')}
            type='text'
            variant='outlined'
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <TextField
            margin='normal'
            fullWidth
            id='event venue'
            label='Enter event venue'
            defaultValue={singleEvent?.event_venu}
            {...register('event_venu')}
            type='text'
            autoFocus
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            margin='normal'
            fullWidth
            id='file'
            label='Choose event Photo'
            InputLabelProps={{ shrink: true }}
            {...register('event_photo')}
            type='file'
          />
        </Grid>
        {/* <Grid item xs={12} md={6} lg={6}>
          <TimePicker
            label='Start Time'
            value={startValue}
            onChange={(newValue) => setStartValue(newValue)}
          />
        </Grid> */}
        <Grid item xs={12} md={6} lg={6} mt={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label='Start time'
              onChange={(value: Dayjs | null) => {
                setTimeValue({
                  ...timeValue,
                  event_start_time: value,
                });
              }}
            />
          </LocalizationProvider>
        </Grid>
        {/* <Grid item xs={12} md={6} lg={6}>
          <TimePicker
            label='End Time'
            value={endValue}
            onChange={(newValue) => setEndValue(newValue)}
          />
        </Grid> */}
        <Grid item xs={12} md={6} lg={6} mt={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label='End time'
              onChange={(value: Dayjs | null) =>
                setTimeValue({
                  ...timeValue,
                  event_end_time: value,
                })
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            label='Date'
            fullWidth
            type='date'
            onChange={handleDateChange}
            // defaultValue={getCurrentDate()}
            InputLabelProps={{ shrink: true }}
            {...register('event_date')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormControl fullWidth>
            {' '}
            <InputLabel id='demo-simple-select-label'>status</InputLabel>{' '}
            <Select
              id='outlined-required'
              value={status}
              label='Status'
              onChange={handleStatusChange}
            >
              <MenuItem value='upcoming'>upcoming</MenuItem>{' '}
              <MenuItem value='ended'>ended</MenuItem>
              <MenuItem value='rejected'>rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={12} mt={2}>
          <TextField
            rows={6}
            maxRows={8}
            multiline
            defaultValue={singleEvent?.event_description}
            {...register('event_description')}
            label='Enter event description'
            fullWidth
          />
        </Grid>
      </Grid>
      {loading ? (
        <Button
          disabled
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
        >
          Loading...
        </Button>
      ) : (
        <Button
          type='submit'
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
        >
          Update Event
        </Button>
      )}
    </Box>
  );
};

export default EventInfoUpdate;

import React from 'react';
import { Container, Grid } from '@mui/material';
import MemberShow from './MemberShow';

const MemberList = () => {
  return (
    <>
      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'
          spacing={3}
        >
          <Grid item xs={12}>
            <MemberShow />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MemberList;

import { Container, Grid } from '@mui/material';
import React from 'react';

import AdminShow from './AdminShow';

const AdminList = () => {
  return (
    <>
      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'
          spacing={3}>
          <Grid item xs={12}>
            <AdminShow />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AdminList;

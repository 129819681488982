import React, { useState } from "react";
import { Button } from "@mui/material";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import Excel from "exceljs";
import axios from "axios";
import { saveAs } from "file-saver";

const B2BReportExcelGeneration = ({
  getDownloadData,
  fileName,
}: {
  getDownloadData: () => Promise<{ data: any }>;
  fileName: string;
}) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    setLoading(true);
    const { data } = await getDownloadData();
    try {
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(fileName);

      const titleRow = worksheet.addRow([
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "Company Name",
      ]);

      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });

      fileName === "b2b_report"
        ? await Promise.all(
            data.map(async (item) => {
              const row = worksheet.addRow([
                item.b2b_first_name,
                item.b2b_last_name,
                item.b2b_email,
                item.b2b_phone,
                item.b2b_organization,
              ]);

              if (item.training_course_application_photo) {
                row.height = 100;
                worksheet.columns.forEach((column) => {
                  column.width = 15;
                });
              }
            })
          )
        : await Promise.all(
            data.map(async (item) => {
              const row = worksheet.addRow([
                item.b2b_first_name,
                item.b2b_last_name,
                item.b2b_email,
                item.b2b_phone,
                item.b2b_organization,
              ]);

              if (item.training_course_application_photo) {
                row.height = 100;
                worksheet.columns.forEach((column) => {
                  column.width = 15;
                });
              }
            })
          );

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      setLoading(false);
      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      setLoading(false);
      console.error("Something Went Wrong", error.message);
    }
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button onClick={saveExcel} variant="outlined" size="medium">
          Download excel
        </Button>
      )}
    </>
  );
};

export default B2BReportExcelGeneration;

// get image buffer
async function getImageBuffer(url: string) {
  try {
    const imgBuffer = await axios.get(url, {
      responseType: "arraybuffer",
    });
    return imgBuffer.data;
  } catch (err) {
    return null;
  }
}

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import PrivateRoute from './Reducers/AuthReducer/PrivateRoute';
import CreateAdmin from './content/modules/Admin/CreateAdmin';
import AdminList from './content/modules/Admin/AdminList';
import MemberList from './content/modules/Members/subModules/memberList/allMember/MemberList';
import InvoiceList from './content/modules/Members/subModules/invoice/allInvoice/InvoiceList';
import RecentOrders from './content/modules/Transactions/RecentOrders';
import ForgetPassword from './content/pages/Forget/ForgetPassword';
import ChangePassword from './content/pages/changePassword/ChangePassword';
import OtpSend from './content/pages/otp/OtpSend';
import CreateInvoice from './content/modules/Members/subModules/invoice/createInvoice';
import CompanyInfoDetails from './content/modules/Members/subModules/memberList/singleMember';
import Trainee from './content/modules/Training/subModules/trainee/allTrainee/Index';
import SingleTrainee from './content/modules/Training/subModules/trainee/singleTrainee/Index';
import Meeting from './content/modules/Members/Meeting/Index';
import SingleMeetingPage from './content/modules/Members/Meeting/singleMeeting/Index';
import SMS from './content/modules/sms';
import CreateMeeting from './content/modules/Members/Meeting/CreateMeeting';
import PaymentableItemList from './content/modules/PaymentableItem/AllPaymentableItem/PaymentableItemList';
import SinglePaymentableItem from './content/modules/PaymentableItem/SinglePaymentableItem/Index';
import CreatePaymentableItem from './content/modules/PaymentableItem/CreatePaymentableItem/Index';
import SingleAdmin from './content/modules/Admin/singleAdmin/SingleAdmin';
import FairStallList from './content/modules/FairStall/FairStallList';
import CreateFairStall from './content/modules/FairStall/CreateFairStall/CreateFairStall';
import CompanyInvoices from './content/modules/Members/subModules/memberList/singleMember/CompanyInfoDetailsComponents/CompanyInvoices';
import SingleFairMemberPage from './content/modules/AllFairMember/SingleFairMember/Index';
import ExhibitorInvoice from './content/modules/exhibitor/exhibitorInvoice/ExhibitorInvoice';
import CreateExhibitorInvoice from './content/modules/exhibitor/CreateExhibitorInvoice';
import SingleVisitor from './content/modules/visitor/singleVisitor/SingleVisitor';
import SingleStallApplication from './content/modules/Fair/stallApplication/singleStallApplication/SingleStallApplication';
import ExhibitorSingleInvoice from './content/modules/exhibitor/exhibitorInvoice/singleInvoice/ExhibitorSingleInvoice';
import ExhibitorSingleProduct from './content/modules/AllFairMember/SingleFairMember/SingleProductByExhibitor';
import EntryFairList from './content/modules/EntryFair/EntryFair';
import StallApplication from './content/modules/Fair/stallApplication/StallApplication';
import SingleEvent from './content/modules/FairEvent/SingleEvent/SingleEvent';
import AllEventList from './content/modules/FairEvent/AllEventList/AllEventList';
import CreateEvent from './content/modules/FairEvent/CreateEvent/CreateEvent';
import VisitorList from './content/modules/visitor/allVisitorList/AllVisitorList';
import SingleVisitorInvoicePage from './content/modules/visitorInvoice/SingleVisitor/SingleVisitor';
import VisitorInvoice from './content/modules/visitorInvoice/Index';
import AllFairMember from './content/modules/AllFairMember/Index';
import SingleFairApplicationPage from './content/modules/Fair/SingleFair/Index';
import AdminFair from './content/modules/Fair/Index';
import AllGuestList from './content/modules/GuestAndSpecker/AllGuestAndSpeaker';
import SingleGuestAndSpeaker from './content/modules/GuestAndSpecker/SingleGuestAndSpeaker';
import B2BList from './content/modules/B2B/allB2BList/AllB2Blist';
import SingleB2B from './content/modules/B2B/singleB2B/SingleB2B';
import B2BInvoice from './content/modules/B2B/b2binvoice/B2BInvoice';
import B2BSingleInvoice from './content/modules/B2B/b2binvoice/singleB2BInvoice/B2BSingleInvoice';
import CreateB2BInvoice from './content/modules/B2B/b2binvoice/CreateB2BInvoice';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const SingleInvoicePage = Loader(
  lazy(
    () =>
      import(
        './content/modules/Members/subModules/invoice/singleInvoice/SingleInvoicePage'
      )
  )
);

// login
const Login = Loader(lazy(() => import('./content/pages/Login/Login')));

// Dashboards
const DashboardOverview = Loader(
  lazy(() => import('src/content/modules/dashboard'))
);

// Applications
const Messenger = Loader(lazy(() => import('src/content/modules/Messenger')));
const Transactions = Loader(
  lazy(() => import('src/content/modules/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/modules/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/modules/Users/settings'))
);

// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '/',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/',
        element: <Navigate to='dashboard' replace />,
      },
      {
        path: 'dashboard',
        element: <DashboardOverview />,
      },

      {
        path: 'status',
        children: [
          {
            path: '/status',
            element: <Navigate to='404' replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: '500',
            element: <Status500 />,
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />,
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />,
          },
        ],
      },
    ],
  },
  {
    path: 'support',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/support',
        element: <Navigate to='conversations' replace />,
      },
      {
        path: 'conversations',
        element: <Messenger />,
      },
    ],
  },
  {
    path: 'invoices',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/invoices',
        element: <Navigate to='transactions' replace />,
      },
      {
        path: 'transactions',
        element: <InvoiceList />,
      },
      {
        path: 'transactions/:id',
        element: <SingleInvoicePage />,
      },
      {
        path: 'create-invoice',
        element: <CreateInvoice />,
      },
    ],
  },
  {
    path: 'create-admin',
    element: <SidebarLayout />,
    children: [
      {
        path: '/create-admin',
        element: <CreateAdmin />,
      },
    ],
  },
  {
    path: 'recent',
    element: <SidebarLayout />,
    children: [
      {
        path: '/recent',
        element: <RecentOrders />,
      },
    ],
  },

  {
    path: 'paymentable-item',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/paymentable-item',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <PaymentableItemList />,
      },
      {
        path: 'list/:paymentableId',
        element: <SinglePaymentableItem />,
      },
      {
        path: 'create',
        element: <CreatePaymentableItem />,
      },
    ],
  },

  {
    path: 'member-list',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/member-list',
        element: <MemberList />,
      },
      {
        path: ':memberId/invoice',
        element: <CompanyInvoices />,
      },
      {
        path: ':memberId',
        element: <CompanyInfoDetails />,
      },
      {
        path: 'meeting',
        element: <Meeting />,
      },
      {
        path: 'meeting/:meetingId',
        element: <SingleMeetingPage />,
      },
      {
        path: 'create-meeting',
        element: <CreateMeeting />,
      },
    ],
  },
  {
    path: 'courses',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/courses',
        element: <Navigate to='batch' replace />,
      },
      {
        path: 'batch',
        element: <Trainee />,
      },
      {
        path: 'trainee',
        element: <Trainee />,
      },
      {
        path: 'trainee/:traineeId',
        element: <SingleTrainee />,
      },
    ],
  },

  // This is entry fair module routing file
  {
    path: 'entry-fair',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/entry-fair',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <EntryFairList />,
      },
    ],
  },
  // This is fair module routing file
  {
    path: 'fair',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/fair',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <AdminFair />,
      },
      {
        path: 'list/:singleFairId',
        element: <SingleFairApplicationPage />,
      },
      {
        path: 'stall-list',
        element: <FairStallList />,
      },
      {
        path: 'create-stall',
        element: <CreateFairStall />,
      },
      {
        path: 'stall-application',
        element: <StallApplication />,
      },
      {
        path: 'stall-application/:stallApplicationId',
        element: <SingleStallApplication />,
      },
    ],
  },

  {
    path: 'guest-speaker',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/guest-speaker',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'guest-list',
        element: <AllGuestList />,
      },
      {
        path: 'guest-list/details/:id',
        element: <SingleGuestAndSpeaker />,
      },
    ],
  },
  {
    path: 'exhibitor',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/exhibitor',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <AllFairMember />,
      },
      {
        path: 'list/:singleMemberFairId',
        element: <SingleFairMemberPage />,
      },
      {
        path: 'list/:singleMemberFairId/product/:ExhibitorSingleProduct',
        element: <ExhibitorSingleProduct />,
      },
      {
        path: 'invoice-list',
        element: <ExhibitorInvoice />,
      },
      {
        path: 'invoice-list/:singleExhibitorInvoiceId',
        element: <ExhibitorSingleInvoice />,
      },
      {
        path: 'create-invoice',
        element: <CreateExhibitorInvoice />,
      },
    ],
  },
  {
    path: 'visitor',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/visitor',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <VisitorList />,
      },
      {
        path: 'list/:singleVisitorId',
        element: <SingleVisitor />,
      },
      {
        path: 'visitor-invoice',
        element: <VisitorInvoice />,
      },
      {
        path: 'visitor-invoice/:visitorInvoiceId',
        element: <SingleVisitorInvoicePage />,
      },
      {
        path: 'create-invoice',
        element: <SingleFairMemberPage />,
      },
    ],
  },

  {
    path: 'b2b',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/b2b',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <B2BList />,
      },
      {
        path: 'list/:singleB2BId',
        element: <SingleB2B />,
      },
      {
        path: 'b2b-invoice',
        element: <B2BInvoice />,
      },
      {
        path: 'b2b-invoice/:b2b_invoice_id',
        element: <B2BSingleInvoice />,
      },
      {
        path: 'create-b2b-invoice',
        element: <CreateB2BInvoice />,
      },
    ],
  },

  {
    path: 'fair-event',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/fair-event',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'create-event',
        element: <CreateEvent />,
      },
      {
        path: 'all-event-list',
        element: <AllEventList />,
      },
      {
        path: 'all-event-list/:eventId',
        element: <SingleEvent />,
      },
    ],
  },

  {
    path: 'admin-list',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/admin-list',
        element: <AdminList />,
      },
      {
        path: 'single-admin/:id',
        element: <SingleAdmin />,
      },
    ],
  },
  {
    path: 'sms',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/sms',
        element: <SMS />,
      },
    ],
  },

  {
    path: 'profile',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/profile',
        element: <Navigate to='details' replace />,
      },
      {
        path: 'details',
        element: <UserProfile />,
      },
      {
        path: 'settings',
        element: <UserSettings />,
      },
    ],
  },

  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'forget-password',
    element: <ForgetPassword />,
  },
  {
    path: 'forget-password/otp',
    element: <OtpSend />,
  },
  {
    path: 'change-password/*',
    element: <ChangePassword />,
  },

  {
    path: '*',
    element: <Status404 />,
  },
];

export default routes;

import { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import {
  ISingleProductByExhibitor,
  SingleExhibitorInvoiceInfo,
} from 'src/Utils/type';
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { getStatusLabel } from 'src/Utils/Combined';
import EditIcon from '@mui/icons-material/Edit';
import SingleProductUpdateForm from './SingleProductUpdateForm';

const ExhibitorSingleProduct = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [singleProductExhibitor, setSingleProductExhibitor] =
    useState<ISingleProductByExhibitor | null>({} as ISingleProductByExhibitor);
  const [loading, setLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);
  const { ExhibitorSingleProduct, singleMemberFairId } = useParams();

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .get(
        `${url}/api/fair/get/single/fair-member-product/by/${singleMemberFairId}/${ExhibitorSingleProduct}`,
        {
          cancelToken: source.token,
        }
      )
      .then((data) => {
        setSingleProductExhibitor(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [ExhibitorSingleProduct]);

  return (
    <div>
      <Helmet>
        <title>BTTF - Single exhibitor product information</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {!loading ? (
            <Container>
              <Box sx={{ py: 2 }}>
                <Button onClick={() => navigate(-1)}>Go back</Button>
              </Box>
              <Card>
                <Box textAlign='end' sx={{ p: 2 }}>
                  <Tooltip title='Edit'>
                    <IconButton onClick={handleOpen} sx={{ cursor: 'pointer' }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <CardContent>
                  <Box key={singleProductExhibitor.fair_member_product_id}>
                    <Typography
                      textAlign='center'
                      gutterBottom
                      variant='h2'
                      component='div'
                    >
                      Single product exhibitor information
                    </Typography>
                    <Typography
                      textAlign='center'
                      variant='body2'
                      color='text.secondary'
                    >
                      {singleProductExhibitor?.fair_member_product_description}
                    </Typography>
                    <Box sx={{ m: 3 }}>
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        <Grid item xs={2} sm={4} md={4}>
                          <Box sx={{ display: 'flex' }}>
                            <img
                              width={30}
                              height={30}
                              style={{
                                borderRadius: '50%',
                                marginRight: '10px',
                              }}
                              src={
                                singleProductExhibitor.fair_member_product_photo
                                  ? `${imgUrl}/fair_files/${singleProductExhibitor.fair_member_product_photo}`
                                  : '/static/images/avatars/avatar2.png'
                              }
                              alt=''
                            />
                            <Typography
                              variant='body1'
                              fontWeight='bold'
                              color='text.primary'
                              display='inline'
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Product Name:{' '}
                            </Typography>
                            {singleProductExhibitor.fair_member_product_name}
                          </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Product price:
                          </Typography>
                          {singleProductExhibitor?.fair_member_product_price}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Product special price:
                          </Typography>
                          {
                            singleProductExhibitor?.fair_member_special_product_price
                          }
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Product description:
                          </Typography>
                          {
                            singleProductExhibitor?.fair_member_product_description
                          }
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Product url:
                          </Typography>
                          <a
                            href={
                              singleProductExhibitor?.fair_member_product_url
                            }
                            target='_blank'
                          >
                            Website link
                          </a>
                        </Grid>
                        {singleProductExhibitor.user_fair_member_product_created_at && (
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              variant='body1'
                              fontWeight='bold'
                              color='text.primary'
                              display='inline'
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Product created date:
                            </Typography>
                            {moment(
                              singleProductExhibitor.user_fair_member_product_created_at
                            ).format('LL')}
                          </Grid>
                        )}
                        {singleProductExhibitor.user_fair_member_product_updated_at && (
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              variant='body1'
                              fontWeight='bold'
                              color='text.primary'
                              display='inline'
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Product updated date:
                            </Typography>
                            {moment(
                              singleProductExhibitor.user_fair_member_product_updated_at
                            ).format('LL')}
                          </Grid>
                        )}
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Product status:
                          </Typography>
                          {getStatusLabel(
                            singleProductExhibitor?.user_fair_member_product_status
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby='responsive-dialog-title'
                    >
                      <DialogTitle sx={{ p: 4 }} id='responsive-dialog-title'>
                        <Typography variant='h4'>
                          Product Status Update{' '}
                        </Typography>
                      </DialogTitle>
                      <DialogContent>
                        {/* <ExhibitorInvoiceUpdateForm
                              details={singleProductExhibitor}
                              setReload={setReload}
                              handleClose={handleClose}
                            /> */}
                        <SingleProductUpdateForm
                          details={singleProductExhibitor}
                          setReload={setReload}
                          handleClose={handleClose}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </CardContent>
              </Card>
            </Container>
          ) : (
            <GlobalLoader />
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default ExhibitorSingleProduct;

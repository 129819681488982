import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { singleVisitorInfo } from 'src/Utils/type';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { imgUrl } from 'src/Helpers/utils/Constant';

interface IProps {
  visitorInfo: any;
}

const SingleB2BInfo = ({ visitorInfo }: IProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <Box sx={{ py: 2 }}>
        <Button onClick={() => navigate(-1)}>Go back</Button>
      </Box>
      <Card>
        <CardContent>
          <Typography
            textAlign='center'
            gutterBottom
            variant='h3'
            component='div'
          >
            Single Buyer/Seller B2B Meeting Information
          </Typography>
          <Typography textAlign='center' variant='body2' color='text.secondary'>
            {visitorInfo?.b2b_first_name} {visitorInfo?.b2b_last_name}
          </Typography>
          <Box sx={{ m: 3 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  B2B ID:
                </Typography>
                {visitorInfo?.b2b_id}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  B2B Name:
                </Typography>
                {visitorInfo?.b2b_first_name} {visitorInfo?.b2b_last_name}
              </Grid>
              {/* <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Profession:
                </Typography>
                {visitorInfo?.b2b_profession
                  ? visitorInfo?.b2b_profession
                  : 'Not Provided'}
              </Grid> */}
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Organization:
                </Typography>
                {visitorInfo.b2b_organization
                  ? visitorInfo.b2b_organization
                  : 'Not Provided'}
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Email:
                </Typography>
                {visitorInfo?.b2b_email}
              </Grid>
              {visitorInfo.b2b_designation && (
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Designation:
                  </Typography>
                  {visitorInfo.b2b_designation}
                </Grid>
              )}
              {visitorInfo.b2b_date_of_birth && (
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Date of Birth:
                  </Typography>
                  {moment(visitorInfo.b2b_date_of_birth).format('LL')}
                </Grid>
              )}

              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Contact number:
                </Typography>
                {visitorInfo.b2b_phone}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Visiting Card:
                </Typography>
                <a
                  target='blank'
                  href={
                    imgUrl +
                    '/b2b_files/' +
                    visitorInfo?.b2b_scan_copy_visiting_card
                  }
                >
                  Download File
                </a>
              </Grid>
              {/* <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Education:
                </Typography>
                {visitorInfo.b2b_education
                  ? visitorInfo.b2b_education
                  : 'Not Provided'}
              </Grid> */}

              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Address:
                </Typography>
                {visitorInfo?.visitor_address
                  ? visitorInfo?.visitor_address
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Country:
                </Typography>
                {visitorInfo?.b2b_country
                  ? visitorInfo?.b2b_country
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  State:
                </Typography>
                {visitorInfo?.b2b_state
                  ? visitorInfo?.b2b_state
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  City:
                </Typography>
                {visitorInfo?.b2b_city ? visitorInfo?.b2b_city : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Post Code:
                </Typography>
                {visitorInfo?.b2b_post_code
                  ? visitorInfo?.b2b_post_code
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Telephone No:
                </Typography>
                {visitorInfo?.b2b_telephone_number
                  ? visitorInfo?.b2b_telephone_number
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Passport No:
                </Typography>
                {visitorInfo?.b2b_passport
                  ? visitorInfo?.b2b_passport
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Passport Issue Date:
                </Typography>
                {visitorInfo?.b2b_date_of_issue
                  ? dayjs(visitorInfo?.b2b_date_of_issue).format('LL')
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Passport Expiry Date:
                </Typography>
                {visitorInfo?.b2b_date_of_expiry
                  ? dayjs(visitorInfo?.b2b_date_of_expiry).format('LL')
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Passport Copy:
                </Typography>
                <a
                  target='blank'
                  href={
                    imgUrl + '/b2b_files/' + visitorInfo?.b2b_passport_scan_copy
                  }
                >
                  Download File
                </a>
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Date of Birth:
                </Typography>
                {visitorInfo?.b2b_date_of_birth
                  ? dayjs(visitorInfo?.b2b_date_of_birth).format('LL')
                  : 'Not Provided'}
              </Grid>
              {/* <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Type:
                </Typography>
                {visitorInfo?.b2b_type}
              </Grid> */}
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Website:
                </Typography>
                {visitorInfo?.b2b_website
                  ? visitorInfo?.b2b_website
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Department:
                </Typography>
                {visitorInfo?.b2b_department
                  ? visitorInfo?.b2b_department
                  : 'Not Provided'}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Business in Bangladesh:
                </Typography>
                {visitorInfo?.b2b_count_business
                  ? visitorInfo?.b2b_count_business
                  : ' '}{' '}
                BDT
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SingleB2BInfo;

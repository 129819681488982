import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { getStatusLabel } from 'src/Utils/Combined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import moment from 'moment';
import axios from 'axios';
import Toaster from 'src/Toaster/Toaster';
import DeleteConfirmation from 'src/Utils/DeleteConfirmation';
import { useState } from 'react';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { Link } from 'react-router-dom';
import { IStallFairApplication } from 'src/Types/module/fair/fairMember';

interface IProps {
  allAdmin: IStallFairApplication;
  setReload: Function;
}

const StallApplicationTableRow = ({ allAdmin, setReload }: IProps) => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const Toast = Toaster();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (itemId: any) => {
    if (allAdmin.fair_stall_application_id && user.user_admin_id) {
      try {
        const { data } = await axios.delete(
          `${url}/api/fair/stall/delete/${allAdmin.fair_stall_application_id}`
        );
        ({ data });
        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: 'Fair stall successfully deleted',
          });
          setReload((prev: boolean) => !prev);
          setOpen(false);
        }
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: `Fair stall doesn't delete`,
        });
      }
    }
  };

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.fair_stall_application_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.fair_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.fair_stall_application_fair_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.user_fair_member_contact_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {allAdmin.fair_stall_application_fair_member_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {moment(allAdmin.fair_start_date).format('LL')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {moment(allAdmin.fair_end_date).format('LL')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {getStatusLabel(allAdmin.fair_stall_application_status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Link
          to={`/fair/stall-application/${allAdmin.fair_stall_application_id.toString()}`}
        >
          <Tooltip title='view' arrow>
            <IconButton
              sx={{
                '&:hover': {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color='inherit'
              size='small'
            >
              <VisibilityIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Link>
        {/* <Tooltip title="Delete" arrow>
          <IconButton
            onClick={handleClickOpen}
            sx={{
              "&:hover": { background: theme.colors.error.lighter },
              color: theme.palette.error.main,
            }}
            color="inherit"
            size="small"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <DeleteConfirmation
          open={open}
          handleClose={handleClose}
          text="Are you want delete this fair invoice?"
          handleDeleteFun={handleDelete}
        /> */}
      </TableCell>
    </TableRow>
  );
};

export default StallApplicationTableRow;

import React from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import { url } from 'src/Helpers/utils/Constant';
import { ICompanyInfo } from 'src/Types/module/member/memberTypes';

const OrganizationInfoDetails = ({
  companyInfo,
}: {companyInfo: ICompanyInfo}) => {
  const {
    member_organization_name,
    member_organization_company_logo,
    member_organization_full_address,
    member_organization_police_station,
    member_organization_application_date,
    member_organization_phone_number,
    member_organization_mobile_number,
    member_organization_fax,
    member_organization_email,
    member_organization_website,
    member_organization_establishment_date,
  } = companyInfo || {};
  return (
    <Box className='bg-style' mr={1}>
      <Box className='mt-2 company-info'>
        <h2>Organization information</h2>
        <ul>
          <li>
            <span>Name of the organization</span>:{' '}
            <p>
              {member_organization_name ? member_organization_name : 'Null'}
            </p>
          </li>
          <li>
            <span>Organization logo</span>:{' '}
            <p>
              {member_organization_company_logo ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_company_logo}`}>
                  {member_organization_company_logo}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Organization Phone number</span>:{' '}
            <p>
              {member_organization_phone_number
                ? member_organization_phone_number
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Organization mobile number</span>:{' '}
            <p>
              {member_organization_mobile_number
                ? member_organization_mobile_number
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Organization fax number</span>:{' '}
            <p>{member_organization_fax ? member_organization_fax : 'Null'}</p>
          </li>
          <li>
            <span>Organization full address</span>:{' '}
            <p>
              {member_organization_full_address
                ? member_organization_full_address
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Police station</span>:{' '}
            <p>
              {member_organization_police_station
                ? member_organization_police_station
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Organization application date</span>:{' '}
            <p>
              {member_organization_application_date
                ? moment(member_organization_application_date).format(
                    'MMM Do YY'
                  )
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Organization application date</span>:{' '}
            <p>
              {member_organization_application_date
                ? moment(member_organization_application_date).format(
                    'MMM Do YY'
                  )
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Organization email address </span>:{' '}
            <p>
              {member_organization_email ? member_organization_email : 'Null'}
            </p>
          </li>

          <li>
            <span>Orgaznization Website</span>:{' '}
            <p>
              <a
                href={member_organization_website}
                target='_blank'
                rel='noopener noreferrer'>
                {member_organization_website
                  ? member_organization_website
                  : 'Null'}
              </a>
            </p>
          </li>
        
        </ul>
      </Box>
    </Box>
  );
};

export default OrganizationInfoDetails;

import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ImodalsWrapperProps } from 'src/Types/module/common/commonTypes';

const ModalsWrapper = ({
  children,
  showModal,
  setShowModal,
  modalData,
}: ImodalsWrapperProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const { dispatch, showModal, modalData } = useModalContext();

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={showModal}
        onClose={() => {
          return setShowModal(!showModal);
        }}
        aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title'>
          <Typography variant='h4'> {modalData?.title}</Typography>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        {/* <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              dispatch({ type: MODAL_HIDE });
            }}>
            Disagree
          </Button>
          <Button
            onClick={() => {
              dispatch({ type: MODAL_HIDE });
            }}
            autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default ModalsWrapper;

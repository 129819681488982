import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Chip,
  Autocomplete,
} from '@mui/material';
import { muiStyles } from 'src/Utils/Styles';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import {
  IpaymentableItem,
  IuserMemberList,
} from 'src/Types/module/invoice/invoiceTypes';
import Toaster from 'src/Toaster/Toaster';
import { LoadingButton } from '@mui/lab';
import SuspenseLoader from 'src/components/SuspenseLoader';

interface IProps {
  user_member_id: number;
  user_member_company_name: string;
  user_member_representative_name: string;
}

const useStyles = muiStyles;
const CreateInvoice = () => {
  const classes = useStyles();
  const { user } = useAuthContext();
  const [allPaymentableItem, setAllPaymentableItem] = useState<
    IpaymentableItem[]
  >([]);
  const [userMember, setUserMember] = useState<any>([]);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [isMSgsent, setisMsgSent] = useState<boolean>(false);
  const [membersId, setMembersId] = useState<IProps | null>(null);
  const [paymentableId, setPaymentableId] = useState<string[]>([]);
  const Toast = Toaster();

  useEffect(() => {
    setLoading(true);
    try {
      axios
        .get(`${url}/api/atab/user-member/company-name/get-all`)
        .then(function (res) {
          if (res.data.success) {
            setUserMember(res.data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(function (err) {
          setUserMember([]);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      axios
        .get(`${url}/api/atab/payment/get/all/paymentable/item`)
        .then(function (res) {
          if (res.data.success) {
            setAllPaymentableItem(res.data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(function (err) {
          setAllPaymentableItem([]);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setisMsgSent(true);
    if (!membersId.user_member_id) {
      Toast.fire({
        icon: 'error',
        title: `Select a member`,
      });
    } else if (paymentableId.length === 0) {
      Toast.fire({
        icon: 'error',
        title: `Select a payment able item`,
      });
    } else {
      const selectedMember = paymentableId.map((member) => {
        return parseInt(member.split(' ')[0]);
      });

      try {
        const { data } = await axios.post(
          `${url}/api/atab/invoice/create/for-admin`,
          {
            member_payment_invoice_user_member_id: membersId.user_member_id,
            member_payment_invoice_paymentable_items: selectedMember,
          }
        );
        ({ data });
        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Invoice Successfully created`,
          });
          setisMsgSent(false);
          setPaymentableId([]);
          setMembersId(null);
        }
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: `Invoice doesn't create`,
        });
        setisMsgSent(false);
        setPaymentableId([]);
      }
    }
  };

  return (
    <div>
      {!isLoading ? (
        <>
          <Helmet>
            <title>BTTF - Create invoice</title>
          </Helmet>

          <Container sx={{ mt: 4 }} maxWidth='lg'>
            <Card>
              <CardHeader title='Create a new invoice by paymentable item' />
              <Divider />
              <CardContent>
                <Box
                  component='form'
                  onSubmit={handleSubmit}
                  sx={{ p: 2 }}
                  className=''
                >
                  <Autocomplete
                    sx={{ padding: '12px' }}
                    placeholder='Select Company'
                    value={membersId}
                    onChange={(event: any, newValue: any) => {
                      setMembersId(newValue);
                    }}
                    id='controllable-states-demo'
                    options={userMember}
                    getOptionLabel={(option) => option.user_member_company_name}
                    // sx={{ width: 300 }}
                    renderTags={(value: readonly any[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant='filled'
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} label='Select Company' />
                    )}
                  />

                  <Autocomplete
                    sx={{ padding: '12px' }}
                    multiple
                    id='tags-filled'
                    options={allPaymentableItem?.map(
                      (option: any) =>
                        option.member_paymentable_item_id +
                        ' ' +
                        option.member_paymentable_item_name
                    )}
                    freeSolo
                    onChange={(event: any, newvalue: string[]) =>
                      setPaymentableId(newvalue)
                    }
                    renderTags={(value: readonly any[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant='filled'
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Create a new invoice by paymentable item'
                        placeholder='More..'
                      />
                    )}
                  />

                  <Button
                    type='submit'
                    // fullWidth
                    variant='contained'
                    disableElevation
                    sx={{
                      mt: 3,
                      mb: 2,
                      py: 1,
                      ml: 2,
                    }}
                  >
                    Create a invoice
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Container>
        </>
      ) : (
        <>
          <SuspenseLoader />
        </>
      )}
    </div>
  );
};

export default CreateInvoice;

import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, ReactNode, SetStateAction } from "react";
import {
  invoiceByFairMember,
  IProductByExhibitor,
  IVisitorByExhibitor,
} from "src/Utils/type";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import SingleExhibitorInvoice from "./SingleExhibitorInvoice";
import SuspenseLoader from "src/components/SuspenseLoader";
import VisitorByExhibitor from "./VisitorByExhibitor";
import ProductByExhibitor from "./ProductByExhibitor";

type IProps = {
  memberInvoice: invoiceByFairMember[];
  productByExhibitor: IProductByExhibitor[];
  visitorByExhibitor: IVisitorByExhibitor[];
  setValue: Dispatch<SetStateAction<string>>;
  value: string;
  loading: Boolean;
};

const InvoiceByFairMember = ({
  memberInvoice,
  visitorByExhibitor,
  productByExhibitor,
  setValue,
  value,
  loading,
}: IProps) => {
  let content: ReactNode;
  if (loading) {
    content = <SuspenseLoader />;
  } else if (value === "visitor" && !visitorByExhibitor.length) {
    content = (
      <Typography variant="h2" textAlign="center">
        Not Found
      </Typography>
    );
  } else if (value === "invoice" && !memberInvoice.length) {
    content = (
      <Typography variant="h2" textAlign="center">
        Not Found
      </Typography>
    );
  } else if (value === "product" && !productByExhibitor.length) {
    content = (
      <Typography variant="h2" textAlign="center">
        Not Found
      </Typography>
    );
  } else if (value === "invoice" && memberInvoice.length > 0) {
    content = (
      <SingleExhibitorInvoice
        memberInvoice={memberInvoice}
      />
    );
  } else if (value === "product" && productByExhibitor.length > 0) {
    content = (
      <ProductByExhibitor
        productByExhibitor={productByExhibitor}
      />
    );
  } else if (value === "visitor" && visitorByExhibitor.length > 0) {
    content = (
      <VisitorByExhibitor
        visitorByExhibitor={visitorByExhibitor}
      />
    );
  }
  return (
    <Container maxWidth="lg">
      <>
        <Box sx={{ py: 3 }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              value="invoice"
              label="Invoice"
              icon={<ReceiptTwoToneIcon />}
            />
            <BottomNavigationAction
              value="product"
              label="Product"
              icon={<InventoryTwoToneIcon />}
            />
            <BottomNavigationAction
              value="visitor"
              label="Visitor"
              icon={<PeopleAltTwoToneIcon />}
            />
          </BottomNavigation>
        </Box>
        <Box sx={{ position: "relative", minHeight: "250px" }}>{content}</Box>
      </>
    </Container>
  );
};

export default InvoiceByFairMember;

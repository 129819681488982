import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { url } from 'src/Helpers/utils/Constant';
import { singleVisitorInfo } from 'src/Utils/type';
import SingleB2BInfo from './SingleB2BInfo';

const SingleB2B = () => {
  const [singleVisitorInfo, setSingleVisitorInfo] =
    useState<singleVisitorInfo>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const { singleB2BId } = useParams();

  useEffect(() => {
    const controller = new AbortController();
    (async function () {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/fair/admin/b2b/${singleB2BId}`
        );
        if (data.success) {
          setSingleVisitorInfo(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setSingleVisitorInfo(null);
      }
    })();
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [singleB2BId]);

  return (
    <div>
      <Helmet>
        <title>BTTF - Single Buyer/Seller B2B Meeting Information</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {!loading ? (
            singleVisitorInfo && (
              <SingleB2BInfo visitorInfo={singleVisitorInfo} />
            )
          ) : (
            <GlobalLoader />
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default SingleB2B;

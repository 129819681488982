import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { getStatusLabel } from 'src/Utils/Combined';
import { Box } from '@mui/system';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import VisibilityIcon from '@mui/icons-material/Visibility';

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DeleteConfirmation from 'src/Utils/DeleteConfirmation';
import { useState } from 'react';
import axios from 'axios';
import Toaster from 'src/Toaster/Toaster';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { IGuest } from 'src/Types/module/guestAndSpeaker/guestAndSpeaker';
type IProps = {
  guest: IGuest;
  setAllGuest: any;
  allGuest: any;
};

const GuestAndSpeakerTableRow = ({ guest, allGuest, setAllGuest }: IProps) => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { user } = useAuthContext();

  const handleDelete = (id: number) => {
    (async function () {
      try {
        setIsLoading(true);
        const uri = `${url}/api/fair/for-admin/guest-or-speaker/delete-by/${id}?user_admin_id=${user.user_admin_id}`;
        const { data } = await axios.delete(uri);

        if (!data.success) {
          setIsLoading(false);
          setOpen(false);
          Toaster().fire({
            icon: 'info',
            title: data.message,
          });
        }
        if (data.success) {
          Toaster().fire({
            icon: 'success',
            title: 'Deleted successfully!',
          });
          const filterData = allGuest.filter(
            (sGuest) => sGuest.guest_speaker_id !== id
          );
          setAllGuest(filterData);
          setIsLoading(false);
          setOpen(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  };
  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {guest?.guest_speaker_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              width={30}
              height={30}
              style={{
                borderRadius: '50%',
                marginRight: '10px',
              }}
              src={
                guest?.guest_speaker_photo
                  ? `${imgUrl}/guest-speaker-files/${guest?.guest_speaker_photo}`
                  : '../../../assets/image/avatar2.png'
              }
              alt=''
            />
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {guest?.guest_speaker_name
              .toLowerCase()
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {getStatusLabel(guest?.guest_speaker_status)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {guest?.guest_speaker_type === 'guest' ? 'Guest' : 'Speaker'}
          </Typography>
        </TableCell>
        <TableCell>
          <Box>
            <Link
              to={`/guest-speaker/guest-list/details/${guest?.guest_speaker_id}`}
            >
              <Tooltip title='view' arrow>
                <IconButton
                  sx={{
                    '&:hover': {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color='inherit'
                  size='small'
                >
                  <VisibilityIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Link>

            <Tooltip title='Delete Notice' arrow>
              <IconButton
                onClick={() => setDeleteModal(true)}
                sx={{
                  '&:hover': { background: theme.colors.error.lighter },
                  color: theme.palette.error.main,
                }}
                color='inherit'
                size='small'
              >
                <DeleteTwoToneIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            {/* Delele Confirmation modal  */}
            <DeleteConfirmation
              open={deleteModal}
              handleClose={() => setDeleteModal(false)}
              text='Want to delete this Guest?'
              handleDeleteFun={() => handleDelete(guest.guest_speaker_id)}
              loading={isLoading}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default GuestAndSpeakerTableRow;

import {
  Card,
  CardContent,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import BillingInfo from "./BillingInfo";
import useTheme from "@mui/material/styles/useTheme";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const CardMeeting = ({
  singleInvoiceInfo,
  editMode,
  handleEditModeChange,
}: any) => {
  const theme = useTheme();

  return (
    <Container>
      <Card className="invoice_print_form">
        <CardContent>
          <div className="invoice-card-header">
            <div className="invoice_icon_section">
              {editMode ? (
                <Tooltip onClick={handleEditModeChange} title="Close">
                  <IconButton
                    sx={{ cursor: "pointer", color: theme.colors.primary.main }}
                  >
                    <HighlightOffOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip onClick={handleEditModeChange} title="Edit meeting">
                  <IconButton
                    sx={{ cursor: "pointer", color: theme.colors.primary.main }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          {singleInvoiceInfo && (
            <BillingInfo
              editMode={editMode}
              handleEditModeChange={handleEditModeChange}
              singleInvoiceDetails={singleInvoiceInfo}
            />
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default CardMeeting;

import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { url } from 'src/Helpers/utils/Constant';
import { ICompanyInformationDetailsProps } from 'src/Types/module/member/memberTypes';
const AssociationMeberInfoDetails = ({
  companyInfo,
}: ICompanyInformationDetailsProps) => {
  const {
    member_organization_association_baira,
    member_organization_association_baira_file,
    member_organization_association_haab,
    member_organization_association_haab_file,
    member_organization_association_iata,
    member_organization_association_iata_file,
    member_organization_association_toab,
    member_organization_association_toab_file,
    member_organization_association_tdab,
    member_organization_association_tdab_file,
    member_organization_civil_aviation_tourism_certificate_no,
    member_organization_civil_aviation_tourism_certificate_date,
    member_organization_civil_aviation_tourism_certificate_file,
    member_organization_applicant_signature_file,
  } = companyInfo || {};
  return (
    <Box className='bg-style greenshade' mr={1}>
      <h2>Association membership information</h2>
      <div className='mt-2 company-info'>
        <ul>
          <li>
            <span>Association baira</span>:{' '}
            <p>
              {member_organization_association_baira
                ? member_organization_association_baira === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Association haab document</span>:{' '}
            <p>
              {member_organization_association_baira_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_association_baira_file}`}>
                  {member_organization_association_baira_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Association haab</span>:{' '}
            <p>
              {member_organization_association_haab
                ? member_organization_association_haab === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Association haab document</span>:{' '}
            <p>
              {member_organization_association_haab_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_association_haab_file}`}>
                  {member_organization_association_haab_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Association iata</span>:{' '}
            <p>
              {member_organization_association_iata
                ? member_organization_association_iata === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Association iata document</span>:{' '}
            <p>
              {member_organization_association_iata_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_association_iata_file}`}>
                  {member_organization_association_iata_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Association toab</span>:{' '}
            <p>
              {member_organization_association_toab
                ? member_organization_association_toab === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Association toab document</span>:{' '}
            <p>
              {member_organization_association_toab_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_association_toab_file}`}>
                  {member_organization_association_toab_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Association tdab</span>:{' '}
            <p>
              {member_organization_association_tdab
                ? member_organization_association_tdab === 1
                  ? 'Yes'
                  : 'No'
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Association toab document</span>:{' '}
            <p>
              {member_organization_association_tdab_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_association_tdab_file}`}>
                  {member_organization_association_tdab_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Civil aviation tourism certificate no </span>:{' '}
            <p>
              {member_organization_civil_aviation_tourism_certificate_no
                ? member_organization_civil_aviation_tourism_certificate_no
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Civil aviation tourism certificate date </span>:{' '}
            <p>
              {member_organization_civil_aviation_tourism_certificate_date
                ? moment(
                    member_organization_civil_aviation_tourism_certificate_date
                  ).format('MMM Do YY')
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Civil aviation tourism certificate document </span>:{' '}
            <p>
              {member_organization_civil_aviation_tourism_certificate_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_civil_aviation_tourism_certificate_file}`}>
                  {member_organization_civil_aviation_tourism_certificate_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Applicant signature document </span>:{' '}
            <p>
              {member_organization_applicant_signature_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_applicant_signature_file}`}>
                  {member_organization_applicant_signature_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default AssociationMeberInfoDetails;

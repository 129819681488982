import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import moment from "moment";
import React from "react";
import { imgUrl, url } from "src/Helpers/utils/Constant";

import {
  IjoinedMember,
  IsingleMemberMeeting,
} from "src/Types/module/meeting/singleMemberMeeting";

const MeetingItems = ({
  singleInvoiceDetails,
}: {
  singleInvoiceDetails: IsingleMemberMeeting;
}) => {
  const theme = useTheme();

  return (
    <>
      <Typography
        sx={{ textDecoration: "underline" }}
        mt={8}
        mb={3}
        color="text."
        align="center"
        variant="h4"
      >
        Meeting Information
      </Typography>

      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                ID
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Member name
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Member ID
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Member Joining time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {singleInvoiceDetails?.joinedMember?.map(
              (details: IjoinedMember) => (
                <TableRow>
                  <TableCell>
                    {details?.member_meeting_joined_member_id}
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img
                        width={30}
                        height={30}
                        style={{
                          borderRadius: "50%",
                          marginRight: "10px",
                        }}
                        src={
                          details?.joined_member_photo
                            ? `${imgUrl}/training_trainee_files/${details.joined_member_photo}`
                            : "/static/images/avatars/avatar2.png"
                        }
                        alt="Meeting Member Photo"
                      />

                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {details?.joined_member_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {details?.joined_member_id}
                  </TableCell>
                  <TableCell align="center">
                    {moment(
                      details?.member_meeting_joined_member_joined_time
                    ).format("LLL")}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MeetingItems;

import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import VisitorInvoiceTable from "./VisitorInvoiceTable";
import { IVisitorInvoiceList } from "src/Utils/type";

const VisitorInvoiceShow = () => {
  const [allVisitorInvoice, setAllVisitorInvoice] = useState<IVisitorInvoiceList[]>(
    []
  );
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>("all");

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(
            `${url}/api/fair/visitor//for-admin/visitor-invoice/get/all/${status}`
          )
          .then(function (res) {
            if (res.data.success) {
              setAllVisitorInvoice(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllVisitorInvoice([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status]);

  return (
    <Card>
      <VisitorInvoiceTable
        loading={loading}
        allVisitorInvoice={allVisitorInvoice}
        setStatus={setStatus}
        status={status}
      />
    </Card>
  );
};

export default VisitorInvoiceShow;

import { Card } from '@mui/material';
import MeetingTable from './MeetingTable';

const MeetingShow = () => {
  return (
    <>
      <Card>
        <MeetingTable />
      </Card>
    </>
  );
};

export default MeetingShow;

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import axios from 'axios';
import Toaster from '../../../../Toaster/Toaster';
import { Helmet } from 'react-helmet-async';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { url } from 'src/Helpers/utils/Constant';
import { LoadingButton } from '@mui/lab';

const CreateFairStall = () => {
  const { user } = useAuthContext();
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [fairStall, setFairStall] = useState<String>('');
  const [hallName, setHallName] = useState<String>('');
  const [airChecked, setAirChecked] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const handleStallChange = (e: SelectChangeEvent) => {
    setFairStall(e.target.value as string);
  };

  const onSubmit = async (inputs: any) => {
    if (user.user_admin_id && hallName && fairStall) {
      try {
        setLoading(true);
        const { fair_stall_name, fair_stall_hall_no } = inputs;
        const { data } = await axios.post(`${url}/api/fair/stall/create`, {
          fair_stall_name,
          fair_stall_hall_no,
          fair_stall_type: fairStall,
          fair_stall_hall_name: hallName,
          fair_stall_air_conditioned: airChecked ? 1 : 0,
        });

        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Fair Created Successfully`,
          });
          reset();
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Field required',
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>BTTF - Create Fair stall</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth='lg'>
        <Card>
          <CardHeader title='Create Fair Stall' />
          <Divider />
          <CardContent>
            <Box sx={{ p: 2 }} className=''>
              <Box
                component='form'
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  mt: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      margin='normal'
                      fullWidth
                      label='Fair stall name'
                      required
                      id='outlined-required'
                      {...register('fair_stall_name')}
                      type='text'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='outlined-required'
                      label='Fair stall hall no'
                      {...register('fair_stall_hall_no')}
                      type='text'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl required margin='normal' fullWidth>
                      <InputLabel required id='demo-simple-select-label'>
                        Fair Stall
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={fairStall}
                        label='Stall type'
                        required
                        onChange={handleStallChange}
                      >
                        <MenuItem value='STALL'>STALL</MenuItem>
                        <MenuItem value='CORNER STALL'>CORNER STALL</MenuItem>
                        <MenuItem value='PAVILLION'>PAVILLION</MenuItem>
                        <MenuItem value='SEMI-PAVILLION'>
                          SEMI-PAVILLION
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl required margin='normal' fullWidth>
                      <InputLabel required id='demo-simple-select-label'>
                        Hall name
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={hallName}
                        label='Hall name'
                        required
                        onChange={(e) => {
                          setHallName(e.target.value);
                        }}
                      >
                        <MenuItem value='Hormone'>Hormone</MenuItem>
                        <MenuItem value='Milky Way'>Milky Way</MenuItem>
                        <MenuItem value='Carnival'>Carnival</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      label='Air condition'
                      control={
                        <Checkbox
                          checked={airChecked}
                          onChange={() => setAirChecked((prev) => !prev)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                    />
                  </Grid>
                </Grid>

                {!loading ? (
                  <Button
                    type='submit'
                    fullWidth
                    disableElevation
                    variant='contained'
                    sx={{
                      mt: 3,
                      mb: 2,
                      py: 1,
                    }}
                  >
                    Create Fair stall
                  </Button>
                ) : (
                  <LoadingButton
                    loading
                    fullWidth
                    disableElevation
                    loadingIndicator='Creating...'
                    variant='outlined'
                  >
                    Creating...
                  </LoadingButton>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default CreateFairStall;

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { url } from "src/Helpers/utils/Constant";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditIcon from "@mui/icons-material/Edit";
import { IPaymentableItemList } from "src/Types/module/paymentableItem/itemList";
import UpdatePaymentableItem from "../UpdatePaymentable/Index";

function SinglePaymentableItem() {
  const theme = useTheme();
  const { paymentableId } = useParams();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { user } = useAuthContext();
  const [allPayments, setAllPayments] = useState<IPaymentableItemList[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    try {
      if (user?.user_admin_id) {
        axios
          .get(
            `${url}/api/atab/payment/get/single/paymentable/item/${paymentableId}`
          )
          .then(function (res) {
            if (res.data.success) {
              setAllPayments(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllPayments([]);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [user.user_admin_id, open]);

  return (
    <>
      <Typography
        sx={{ textDecoration: "underline" }}
        mt={8}
        mb={3}
        color="text."
        align="center"
        variant="h4"
      >
        Single Paymentable Information
      </Typography>
      <Container>
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
              <TableRow>
                <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                  ID
                </TableCell>
                <TableCell
                  sx={{ color: theme.colors.alpha.white[100] }}
                  align="center"
                >
                  Item name
                </TableCell>
                <TableCell
                  sx={{ color: theme.colors.alpha.white[100] }}
                  align="center"
                >
                  Paymentable item BDT amount
                </TableCell>
                <TableCell
                  sx={{ color: theme.colors.alpha.white[100] }}
                  align="center"
                >
                  Paymentable item USD amount
                </TableCell>
                <TableCell
                  sx={{ color: theme.colors.alpha.white[100] }}
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allPayments?.map((details: IPaymentableItemList) => (
                <TableRow key={details?.member_paymentable_item_id}>
                  <TableCell>{details?.member_paymentable_item_id}</TableCell>
                  <TableCell align="center">
                    {details?.member_paymentable_item_name}
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {details?.member_paymentable_item_bdt_amount}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <AttachMoneyIcon sx={{ fontSize: "15px" }} />
                    {details?.member_paymentable_item_usd_amount}
                  </TableCell>
                  <TableCell align="center">
                    <EditIcon onClick={handleOpen} sx={{ cursor: "pointer" }} />
                  </TableCell>
                  <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitle id="responsive-dialog-title">
                      <Typography variant="h4">
                        Update Paymentable Item
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <UpdatePaymentableItem
                        details={details}
                        handleClose={handleClose}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleClose}>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}

export default SinglePaymentableItem;

import React from 'react';

import { Box } from '@mui/material';
import { ICompanyInformationDetailsProps } from 'src/Types/module/member/memberTypes';
import { url } from 'src/Helpers/utils/Constant';

const ParticularsOwnerInfoDetails = ({
  companyInfo,
}: ICompanyInformationDetailsProps) => {
  const {
    member_organization_owner_name,
    member_organization_owner_full_address,
    member_organization_owner_photo,
    member_organization_owner_designation,
    member_organization_owner_phone,
    member_organization_owner_nid_no,
    member_organization_owner_nid_front,
    member_organization_owner_nid_back,
    member_organization_owner_bio_data_file,
  } = companyInfo || {};
  return (
    <Box className='bg-style pinkshade'>
      <h2>Particular of the owner / owner's information</h2>
      <div className='mt-2 company-info'>
        <ul>
          <li>
            <span>Owner's name</span>:{' '}
            <p>
              {member_organization_owner_name
                ? member_organization_owner_name
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Owner's photo</span>:{' '}
            <p>
              {member_organization_owner_photo ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_owner_photo}`}>
                  {member_organization_owner_photo}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Owner's full address</span>:{' '}
            <p>
              {member_organization_owner_full_address
                ? member_organization_owner_full_address
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Owner's designation</span>:{' '}
            <p>
              {member_organization_owner_designation
                ? member_organization_owner_designation
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Owner's phone number</span>:{' '}
            <p>
              {member_organization_owner_phone
                ? member_organization_owner_phone
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Owner's NID number</span>:{' '}
            <p>
              {member_organization_owner_nid_no
                ? member_organization_owner_nid_no
                : 'Null'}
            </p>
          </li>
          <li>
            <span>Owner's NID (Front side)</span>:{' '}
            <p>
              {member_organization_owner_nid_front ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_owner_nid_front}`}>
                  {member_organization_owner_nid_front}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Owner's NID (Back side)</span>:{' '}
            <p>
              {member_organization_owner_nid_back ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_owner_nid_back}`}>
                  {member_organization_owner_nid_back}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Owner's bio data</span>:{' '}
            <p>
              {member_organization_owner_bio_data_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_owner_bio_data_file}`}>
                  {member_organization_owner_bio_data_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default ParticularsOwnerInfoDetails;

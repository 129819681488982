import { FC, ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
} from "@mui/material";
import SuspenseLoader from "src/components/SuspenseLoader";
import VisitorTableRow from "./VisitorTableRow";
import { visitorList } from "src/Utils/type";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";

const applyPagination = (
  fairAllMember: visitorList[],
  page: number,
  limit: number
): visitorList[] => {
  return fairAllMember.slice(page * limit, page * limit + limit);
};

const VisitorTable = ({
  fairAllMember,
  loading,
  setStatus,
  status,
  setReload,
}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "pending",
      name: "Pending",
    },
    {
      id: "approved",
      name: "Approved",
    },
    {
      id: "rejected",
      name: "Rejected",
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(fairAllMember, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "320px",
            }}
          >
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        title="Visitor list"
      />

      <Divider />
      <TableContainer>
        <Table sx={{ position: "relative", minHeight: "250px" }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Member name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Visitor type</TableCell>
              <TableCell>visitor_organization</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableLoader />
          ) : paginatedInvoice.length ? (
            <TableBody>
              {paginatedInvoice.map((visitor: visitorList) => {
                return (
                  <VisitorTableRow
                    key={visitor.visitor_id}
                    visitor={visitor}
                    setReload={setReload}
                  />
                );
              })}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={fairAllMember.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default VisitorTable;

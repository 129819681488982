import { ChangeEvent, useState } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  Typography,
} from "@mui/material";

import { Iadmin, adminStatus } from "src/Types/module/admin/adminTypes";
import AdminTableRow from "./AdminTableRow";
import SuspenseLoader from "../../../components/SuspenseLoader/index";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";

interface RecentOrdersTableProps {
  loading?: Boolean;
  allAdmin: Iadmin[];
  status: string;
  setStatus: Function;
}
const applyPagination = (
  allInvoice: Iadmin[],
  page: number,
  limit: number
): Iadmin[] => {
  return allInvoice.slice(page * limit, page * limit + limit);
};

const AdminTable = ({
  allAdmin,
  loading,
  status,
  setStatus,
}: RecentOrdersTableProps) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "pending",
      name: "Pending",
    },
    {
      id: "active",
      name: "active",
    },
    {
      id: "rejected",
      name: "rejected",
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allAdmin, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box width={150}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        title="All Admin"
      />

      <Divider />
      <TableContainer>
        <Table sx={{ position: "relative", minHeight: "250px" }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableLoader />
          ) : paginatedInvoice.length ? (
            <TableBody>
              {paginatedInvoice.map((allAdmin: Iadmin) => (
                <AdminTableRow
                  key={allAdmin.user_admin_id}
                  allAdmin={allAdmin}
                />
              ))}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={allAdmin.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default AdminTable;

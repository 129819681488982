import React from 'react';
import { Box } from '@mui/material';
import { url } from 'src/Helpers/utils/Constant';
import { ICompanyInformationDetailsProps } from 'src/Types/module/member/memberTypes';


const OthersInfoDetails = ({
  companyInfo,
}: ICompanyInformationDetailsProps) => {
  const {
    member_organization_incorporation_certificate_file,
    member_organization_memorandum_and_articles_file,
    member_organization_office_photographs,
    member_organization_office_stuff_list_file,
    member_organization_one_stuff_certificate_file,
    member_organization_status_ownership,
    member_organization_partnership_deed_file,
    member_organization_office_space_or_ownership_deed_file,
  } = companyInfo || {};
  return (
    <Box className='bg-style five'>
      <h2>Other information </h2>
      <div className='mt-2 company-info'>
        <ul>
          <li>
            <span>Incorporation certificate document</span>:{' '}
            <p>
              {member_organization_incorporation_certificate_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_incorporation_certificate_file}`}>
                  {member_organization_incorporation_certificate_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>memorandum and articles document</span>:{' '}
            <p>
              {member_organization_memorandum_and_articles_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_memorandum_and_articles_file}`}>
                  {member_organization_memorandum_and_articles_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>office photographs</span>:{' '}
            <ul>
              {member_organization_office_photographs
                ? member_organization_office_photographs.map(
                    (sphoto: string) => {
                      return (
                        <li key={sphoto}>
                          <p>
                            <a
                              href={`${url}/api/download/file/company_files/${sphoto}`}>
                              {sphoto}
                            </a>
                          </p>
                        </li>
                      );
                    }
                  )
                : 'Null'}
            </ul>
          </li>
          <li>
            <span>Office stuff list document</span>:{' '}
            <p>
              {member_organization_office_stuff_list_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_office_stuff_list_file}`}>
                  {member_organization_office_stuff_list_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Organization one stuff list document</span>:{' '}
            <p>
              {member_organization_one_stuff_certificate_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_one_stuff_certificate_file}`}>
                  {member_organization_one_stuff_certificate_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Organization ownership status</span>:{' '}
            <p>
              {member_organization_status_ownership ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_status_ownership}`}>
                  {member_organization_status_ownership}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>Deed of partnership document </span>:{' '}
            <p>
              {member_organization_partnership_deed_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_partnership_deed_file}`}>
                  {member_organization_partnership_deed_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
          <li>
            <span>
              Deed of agreement of office space / land ownership document
              partnership deed document{' '}
            </span>
            :{' '}
            <p>
              {member_organization_office_space_or_ownership_deed_file ? (
                <a
                  href={`${url}/api/download/file/company_files/${member_organization_office_space_or_ownership_deed_file}`}>
                  {member_organization_office_space_or_ownership_deed_file}
                </a>
              ) : (
                'Null'
              )}
            </p>
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default OthersInfoDetails;

import { ChangeEvent, useState } from "react";
import {
    Divider,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableContainer,
    CardHeader,
} from "@mui/material";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import EntryFairTableRow from "./EntryFairTableRow";
import { IEntryFairList } from "src/Utils/type";

const applyPagination = (
    entryFairList: IEntryFairList[],
    page: number,
    limit: number
): IEntryFairList[] => {
    return entryFairList.slice(page * limit, page * limit + limit);
};

const EntryFairTable = ({
    entryFairList,
    loading,
}) => {
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(25);
    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };
    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };
    const paginatedInvoice = applyPagination(entryFairList, page, limit);
    return (
        <Card>
            <CardHeader
                title="Entry fair List"
            />
            <Divider />
            <TableContainer>
                <Table sx={{ position: "relative", minHeight: "250px" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Fair Time</TableCell>
                            <TableCell>Visitor name</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>

                    {loading ? (
                        <TableLoader />
                    ) : paginatedInvoice.length ? (
                        <TableBody>
                            {paginatedInvoice.map((entryFair: IEntryFairList) => {
                                return (
                                    <EntryFairTableRow
                                        key={entryFair.fair_entry_id}
                                        entryFair={entryFair}
                                    />
                                );
                            })}
                        </TableBody>
                    ) : (
                        <TableNotFound />
                    )}
                </Table>
            </TableContainer>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={entryFairList.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[25, 50, 75, 100]}
                />
            </Box>
        </Card>
    );
};

export default EntryFairTable;

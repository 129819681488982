import { Navigate } from 'react-router-dom';
// import useAuth from '../../components/hooks/useAuth';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import Spinner from 'src/components/Spinner/Spinner';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';

interface IComponent {
  component: JSX.Element;
}

export default function PrivateRoute({ component }: IComponent) {
  // const isLoggedIn = useAuth();
  const { isLoading, user } = useAuthContext();

  // (user);
  if (isLoading) {
    return <GlobalLoader />;
  }

  if (!user?.user_admin_id) {
    return <Navigate to='/login' replace />;
  } else {
    return component;
  }

  // return isLoggedIn ? component : <Navigate to='/login' />;
}

import { Label } from '@mui/icons-material';
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { IPaymentableItemList } from 'src/Types/module/paymentableItem/itemList';
import { muiStyles } from 'src/Utils/Styles';

const useStyles = muiStyles;

function UpdatePaymentableItem({ details, handleClose }) {
  const Toast = Toaster();
  const classes = useStyles();
  const { user } = useAuthContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [bdtAmount, setBDTAmount] = useState<number>(null);
  const [usdAmount, setUSDAmount] = useState<number>(null);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (user.user_admin_id && details.member_paymentable_item_name) {
      try {
        const { data } = await axios.put(
          `${url}/api/atab/payment/update/paymentable/item/${details.member_paymentable_item_id}`,
          {
            member_paymentable_item_name:
              name || details.member_paymentable_item_name,
            member_paymentable_item_bdt_amount:
              bdtAmount || details.member_paymentable_item_bdt_amount,
            member_paymentable_item_usd_amount:
              usdAmount || details.member_paymentable_item_usd_amount,
          }
        );
        ({ data });
        if (data.success) {
          setIsLoading(false);
          Toast.fire({
            icon: 'success',
            title: data.message,
          });
          handleClose();
        }
      } catch (err) {
        err;
        setIsLoading(false);
        Toast.fire({
          icon: 'error',
          title: err.response.data.message,
        });
      }
    }
  };

  return (
    <Box
      component='form'
      onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => handleSubmit(e)}
    >
      <TextField
        margin='normal'
        required
        fullWidth
        type='text'
        id='name'
        defaultValue={details?.member_paymentable_item_name}
        onChange={(e: any) => setName(e.target.value)}
        variant='filled'
        label='Item name'
        sx={{ paddingBottom: '10px' }}
      />
      {/* <TextField
            margin='normal'
            required
            fullWidth
            type='number'
            id='filled-size-small'
            defaultValue={details?.atab_paymentable_item_usd_amount}
            onChange={(e: any) => setUSDAmount(e.target.value)}
            variant='filled'
            label="Item USD amount"
            sx={{ paddingTop: "8px" }}
         /> */}
      <FormControl sx={{ paddingBottom: '20px' }} fullWidth variant='filled'>
        <InputLabel htmlFor='filled-adornment-amount'>
          Item BDT amount
        </InputLabel>
        <FilledInput
          id='filled-adornment-amount'
          type='number'
          required
          defaultValue={details?.member_paymentable_item_bdt_amount}
          onChange={(e: any) => setBDTAmount(e.target.value)}
          startAdornment={<InputAdornment position='start'>৳</InputAdornment>}
        />
      </FormControl>
      <FormControl fullWidth variant='filled'>
        <InputLabel htmlFor='filled-adornment-amount'>
          Item USD amount
        </InputLabel>
        <FilledInput
          id='filled-adornment-amount'
          type='number'
          required
          defaultValue={details?.member_paymentable_item_usd_amount}
          onChange={(e: any) => setUSDAmount(e.target.value)}
          startAdornment={<InputAdornment position='start'>$</InputAdornment>}
        />
      </FormControl>
      <Button
        type='submit'
        fullWidth
        variant='contained'
        disableElevation
        sx={{
          mt: 3,
          mb: 2,
          py: 1,
        }}
      >
        Update paymentable item
      </Button>
    </Box>
  );
}

export default UpdatePaymentableItem;

import { Card, Container, Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import B2BInvoiceTable from './B2BInvoiceTable';

const B2BInvoice = () => {
  const [b2bInvoice, setB2BInvoice] = useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('all');
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/fair/admin/invoice/b2b`)
          .then(function (res) {
            if (res.data.success) {
              console.log(res.data.data);
              setB2BInvoice(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setB2BInvoice([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status]);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <B2BInvoiceTable
              loading={loading}
              allAdmin={b2bInvoice}
              setStatus={setStatus}
              status={status}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default B2BInvoice;
